//import ScheduleDayBreakModel from "./schedule.day.break.model";
//import PropTypes from 'prop-types';

export default class ScheduleDayModel {
    //break = new ScheduleDayBreakModel()
    model = {
        my_type: "Schedule",
        schedule_id: -1,
        start_time: null,
        end_time: null,
        message: "",
        is_clockedin: false,
        emp_fk: null,
        //breaks: [] //new Array(this.break)
    }   
    /*
    static propTypes = {
        my_type: PropTypes.string,
        schedule_id: PropTypes.number,
        start_time: PropTypes.string,
        end_time: PropTypes.string,
        message: PropTypes.string,
        is_clockedin: PropTypes.bool,
        emp_fk: PropTypes.number,
        breaks: PropTypes.array
    }
    static defaultProps = {
        my_type: "Schedule",
        schedule_id: null,
        start_time: null,
        end_time: null,
        message: null,
        is_clockedin: null,
        emp_fk: null,
        breaks: new Array(ScheduleDayBreakModel)
    }*/
    getSchedule(){
        return this.model
    }
    constructor(props){
        if(props)
            this.model = props
    }
}