import { _token } from './global.settings.service';
import NetworkService from './network.service';

export default class ActionService{
    networkService = new NetworkService();
    // Endpoint: https://host/action/
    /*getAllEmployeeActions(callback){
        return this.networkService.GET("action", callback);
    }*/
    getAllEmployeeActions(alpha, beta, callback){
        //Endpoint: http://52.10.0.37/employee/all/activitypagequery//2018-01-03T17:00:00Z/2020-01-04T01:45:00Z/
        return this.networkService.GET(`employee/all/activitypagequery/${alpha}/${beta}`, callback)
    }

    // Endpoint: https://host/action/
    postEmployeeAction(action, callback){
        //let url = baseURL + '/action/';
        return this.networkService.POST("action", action, callback);
    }

    // Endpoint: https://host/employee/<pk>/actions/
    /*getEmployeeActions(employee_id, callback){
        return this.networkService.GET(`employee/${employee_id}/actions/token/${_token}`, callback);
    }*/
    getEmployeeActions(employee_id, alpha, beta, callback){
        return this.networkService.GET(`employee/${employee_id}/activitypagequery/${alpha}/${beta}`, callback);
    }
    
    //Endpoint https://host/employee/(?P<pk>[0-9]+)/hours/between/(?P<alpha>[0-9]+)/(?P<beta>[0-9]+)/option/(?P<option>.+)/$ [name='employee_hours_timeframe-list']
    getEmployeeTotalScheduledHrs(data, callback){
        const {employee_id, start, end} = data
       return this.networkService.GET(`employee/${employee_id}/hours/between/${start}/${end}/option/1`, callback)
    }
}