import React,{Component} from 'react';
import * as googleApi from '../../keys/googleApiKey';
import GoogleMapReact from 'google-map-react';
import EmployeeService from '../../services/employee.service';

export default class Map extends Component {
    employeeService = new EmployeeService();
    constructor(props){
        super(props)
        this.state = {
            google: null,
            map: null,
            geofences: props.geofences,
            markers: [],
            timer: null,
        }
        this.renderMarkers = this.renderMarkers.bind(this);
        this.renderGeofences = this.renderGeofences.bind(this);
        this.removeMarkers = this.removeMarkers.bind(this);
        this.setTimer = this.setTimer.bind(this);
        this.destroyTimer = this.destroyTimer.bind(this);
        this.startTracking = this.startTracking.bind(this);
    }
    componentWillReceiveProps(nextProps){
        if(JSON.stringify(this.props.employeeToTrack) !== JSON.stringify(nextProps.employeeToTrack)){
            this.removeMarkers()
            this.renderMarkers(nextProps.employeeToTrack, nextProps.availableEmployeesToTrack,nextProps.availableUsersToTrack)
        }
        if(this.props.shouldTrack !== nextProps.shouldTrack){
            if(nextProps.shouldTrack)
                this.setTimer()
            else
                this.destroyTimer()
        }        
    }
    setTimer(){
        var intervalId = setInterval(this.startTracking, 3000);
        this.setState({timer: intervalId})
    }
    destroyTimer(){
        clearInterval(this.state.timer)
    }
    startTracking(){
        const {employeeToTrack} = this.props
        if(employeeToTrack.id === "all")
            this.trackAllEmployees()
        else
            this.trackSingleEmployee()
    }
    trackAllEmployees(){
        const {availableEmployeesToTrack, availableUsersToTrack} = this.props
        this.employeeService.getEmployees(res=>{
            let employees = res.success
            if(employees)
                employees.map(employee => this.renderMarkers(employee, availableEmployeesToTrack, availableUsersToTrack))
            else
                console.log("Error retreiving employee")
        })
    }
    trackSingleEmployee(){
        const {employeeToTrack, availableEmployeesToTrack, availableUsersToTrack} = this.props
        this.employeeService.getEmployee(employeeToTrack.id, res =>{
            let employee = res.success
            if(employee)
                this.renderMarkers(employee, availableEmployeesToTrack, availableUsersToTrack)
            else
                console.log("Error retreiving employee")
        })
    }
    getUsername(employeeToTrack ,availableUsersToTrack){
        for(var i=0;i<availableUsersToTrack.length;i++){
            let user = availableUsersToTrack[i]
            if(employeeToTrack.user_id === user.id)
                return `${user.first_name} ${user.last_name}`
        }
        return "username unavailable"
    }
    removeMarkers(){
        const {markers} = this.state
        markers.map(marker=>marker.setMap(null))
        this.setState({markers:[]})
    }
    createMarker(employee, availableUsersToTrack, google, map){
        var username = this.getUsername(employee, availableUsersToTrack);
        var location = new google.LatLng(parseFloat(employee.latitude), parseFloat(employee.longitude));
        let marker = new google.Marker({
            position: location,
            label: {
                color: 'black',
                fontWeight: 'bold',
                text: username,
            },
            map,
        })
        return marker
    }
    renderMarkers(employeeToTrack, availableEmployeesToTrack, availableUsersToTrack) {
        const {google,map} = this.state
        var markers=[];
        
        if(employeeToTrack.id === "all")
            markers = availableEmployeesToTrack.map(employee=>this.createMarker(employee, availableUsersToTrack, google, map))
        else if(employeeToTrack.user_id){
            availableUsersToTrack.map(user =>
                user.id === employeeToTrack.user_id && markers.push(this.createMarker(employeeToTrack, availableUsersToTrack, google, map)) 
            )
        }
        else
            availableEmployeesToTrack.map(employee=>
                employee.user_id === employeeToTrack.id && markers.push(this.createMarker(employee, availableUsersToTrack, google, map))
            )
        markers = markers.concat(this.state.markers)
        this.setState({markers})
    }
    renderGeofences(geofences){
        geofences.map(geofence =>{
            const {google,map} = this.state
            let circle = new google.Circle({
                strokeColor: "#a6a6a6",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#a6a6a6",
                fillOpacity: 0.35,
                map,
                center: {lat: geofence.latitude, lng: geofence.longitude},
                radius: geofence.radius
              });
            return circle
        })
    }
    initializeMap =({map, maps}) =>{ 
        const {geofences,employeeToTrack, availableEmployeesToTrack, availableUsersToTrack} = this.props
        this.setState({google: maps, map:map})
        //this.map = map
        geofences && this.renderGeofences(geofences)
        if(employeeToTrack && availableEmployeesToTrack.length>0)
            this.renderMarkers(employeeToTrack, availableEmployeesToTrack, availableUsersToTrack)
    }

    render() {
        return (
            <GoogleMapReact style={{width:'100%', height:`${window.innerHeight - 98}px`}} bootstrapURLKeys={{ key:googleApi.GOOGLE_API_KEY}}
                center={{lat: 46.24046, lng: -119.13293}} defaultZoom={11} onGoogleApiLoaded={this.initializeMap}
                yesIWantToUseGoogleMapApiInternals>
            </GoogleMapReact>
    );
  }
}