import React,{Component} from 'react';
import {Button} from 'reactstrap';
import SearchList from '../userSearchList';
import PropTypes from 'prop-types';

export default class TrackList extends Component{
    render(){
        const {shouldTrack, defaultUser, users} = this.props
        const usersToTrack = users.length>0
        return(
            <div className="trackingPageLeftPanel">
                <div style={{display: 'inline-flex'}}>
                    <Button disabled={usersToTrack ? shouldTrack : true} size="sm" color="primary" className="clearfix float-left" onClick={this.props.enableLiveTracking}>Enable live tracking</Button> 
                    <Button disabled={usersToTrack ? !shouldTrack : true} size="sm" color="primary" className="clearfix float-right" onClick={this.props.disableLiveTracking}>Disable live tracking</Button>
                </div>
                <SearchList defaultUser={defaultUser} users={users} onClick={this.props.getEmployeeLocation} messages={{emptyList: "There are no employees clocked in to track"}}/>
            </div>
        )
    }
}
TrackList.propTypes = {
    enableLiveTracking: PropTypes.func,
    disableLiveTracking: PropTypes.func,
    users: PropTypes.arrayOf(PropTypes.object),
    defaultUser: PropTypes.object,
    getEmployeeLocation: PropTypes.func,
    shouldTrack: PropTypes.bool
}