import React, {Component} from 'react';
import { ListGroup, ListGroupItem } from 'reactstrap';
import PropTypes from 'prop-types';

export default class List extends Component {
  static propTypes = {
    items: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object])).isRequired,
    format: PropTypes.func,
    onClick: PropTypes.func,
    currentSelection: PropTypes.object
  }
  constructor(props){
    super(props);
    this.state ={
      activeItemIndex: props.defaultUser ? 0 : -1,
      activeItem: {}
    }
    props.defaultUser && this.updateActiveItemIndex(props.items, props.defaultUser)
    this.onClick = this.onClick.bind(this);
  }
  componentDidUpdate(){
    if(JSON.stringify(this.props.currentSelection) !== JSON.stringify(this.state.activeItem))
      this.updateActiveItemIndex(this.props.items, this.props.currentSelection)
  }
  updateActiveItemIndex(items, activeItem){
    for(let activeItemIndex = 0; activeItemIndex<items.length; activeItemIndex++){
      if(JSON.stringify(items[activeItemIndex]) === JSON.stringify(activeItem)){
        this.setState({activeItem, activeItemIndex})
        return
      }
    }    
  }
  onClick(activeItem,activeItemIndex){
    this.props.onClick(activeItem)
    this.setState({activeItemIndex, activeItem})
  }
  render() {
    const {activeItemIndex} = this.state;
    return (
      <ListGroup flush={this.props.flush}>
        {this.props.items.length>0 ?
            this.props.items.map((item,index)=>{
              if(typeof item === "string" || typeof item === "number")
                return <ListGroupItem key={`listGroupItem-${index}`} active={activeItemIndex===index} onClick={()=>this.onClick(item,index)}>{item}</ListGroupItem>
              else if(typeof item === "object")
                return <ListGroupItem key={`listGroupItem-${index}`} active={activeItemIndex===index} onClick={()=>this.onClick(item,index)}>{this.props.format(item)}</ListGroupItem>
              else
                return null
            })
          : <ListGroupItem>No data to display</ListGroupItem>
        }
      </ListGroup>
    );
  }
}