import React from 'react';
import { Navbar, NavbarBrand, Nav, NavItem, NavLink } from 'reactstrap';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

const style={
  backgroundColor: 'rgb(72, 138, 255)',
  height: '80px'
}
export default class NavBar extends React.Component {
  getNavItem(navItem,index){
    switch(navItem.type){
        case "Link":
          return <NavItem key={`navItem-${index}`}><NavLink tag={Link} to={navItem.to}>{navItem.label}</NavLink></NavItem>
        case "btn":
          return <NavItem key={`navItem-${index}`}><NavLink onClick={()=>this.props.onClick(navItem.name)}>{navItem.label}</NavLink></NavItem>
        case "cmp":
            return <NavItem key={`navItem-${index}`}><NavLink>{navItem.cmp}</NavLink></NavItem>
        default:
          console.log("navitem type is not set for this option")

    }
  }
  render() {
    return (
        <Navbar style={style} expand="md">
          <NavbarBrand className="text-white" href="/" style={{fontSize:'2em'}}>iGPS Time</NavbarBrand>
            <Nav className="ml-auto" navbar>
              {this.props.navItems.map((navItem, index) =>this.getNavItem(navItem,index))}
            </Nav>
        </Navbar>
    );
  }
}
NavBar.propTypes = {
  onClick: PropTypes.func,
  navItems: PropTypes.arrayOf(PropTypes.object)
}