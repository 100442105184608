import React,{Component} from 'react';
import { Card, CardTitle, CardImg, CardBody, CardText, Button} from 'reactstrap';
import PropTypes from 'prop-types';
const style = {
    border: '1px solid rgb(245, 245, 245)',
    backgroundColor: 'rgb(245, 245, 245)'
}
 export default class Tile extends Component{
     render(){
         const {src, title, hasCardImg, hasCardText, text, hasButton, buttonLabel, buttonStyling} = this.props;
         return(
            <div className="tile">
                <Card inverse style={style}>
                {
                    hasCardImg && <CardImg top width="100%" src={src} alt="Card image cap" />
                }
                <CardBody>
                    <CardTitle style={{color:'black'}}>
                        {title}
                    </CardTitle>
                    {
                        hasCardText && <CardText>{text}</CardText>
                    }
                    {
                        hasButton && <Button {...buttonStyling}>{buttonLabel}</Button>
                    }
                </CardBody>
                </Card>
          </div>
         )
     }
 }
 Tile.propTypes = {
     src: PropTypes.string,
     title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
     hasCardImg: PropTypes.bool,
     hasCardText: PropTypes.bool,
     text: PropTypes.object,
     hasButton: PropTypes.bool,
     buttonLabel: PropTypes.string,
     buttonStyling: PropTypes.object,
 }