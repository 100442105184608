import React,{Component} from 'react';
import { Button, Form, FormGroup, Label, Input} from 'reactstrap';
import PropTypes from 'prop-types';

export default class GeneralForm extends Component{
  render() {
    const {submissionButtonTitle} = this.props
    return (
      <Form className="generalForm">
        {
            this.props.formItems.map((formItem,index)=>{
                return formItem.type === "checkbox" ?
                    <FormGroup key={`generalForm-formGroup-${index}`}>
                      <Label for={formItem.id}>{formItem.rootLabel}</Label>
                      <div className="custom-checkbox custom-control">
                        <Label check>
                          <Input defaultChecked={formItem.data} id={formItem.id} onChange={(evt)=>this.props.onChange(evt, formItem.id)} type="checkbox"/>{formItem.label}
                        </Label>
                      </div>
                    </FormGroup>
                  :
                    <FormGroup key={`generalForm-formGroup-${index}`} inline={formItem.inline}>
                      <Label for={formItem.id}>{formItem.label}</Label>
                      <Input onChange={(evt)=>this.props.onChange(evt, formItem.id)} type={formItem.type} id={formItem.id} placeholder={formItem.placeholder} />
                    </FormGroup>
                }
            )
        }
        <Button className="clear-fix float-right" size="sm" color="primary" onClick={this.props.onSubmit}>{submissionButtonTitle ? submissionButtonTitle : "Submit"}</Button>
      </Form>
    );
  }
}
GeneralForm.propTypes = {
    formItems: PropTypes.arrayOf(PropTypes.object),
    onSubmit: PropTypes.func,
    onChange: PropTypes.func
}