import NetworkService  from './network.service';
import {_companyID} from './global.settings.service';

export default class CompanyService{
    networkService = new NetworkService()
    getCompanyDetails(callback){
        // let url: string = this.globalSettings.baseURL + '/company/' + this.globalSettings.companyID;
        // return this.networkService.GET(url);
        //let url: string = this.globalSettings.baseURL + '/company/' + this.globalSettings.companyID;
        return this.networkService.GET(`company/${_companyID}`,callback);
    }
    getCompanySettings(callback){
        //let url: string = this.globalSettings.baseURL + '/companysettings/' + this.globalSettings.companyID;
        //return this.networkService.GET(url);
        return this.networkService.GET(`companysettings/${_companyID}`,callback);
    }
    getCompanyGeofences(callback){
        //let url: string = this.globalSettings.baseURL + '/company/' + this.globalSettings.companyID + '/geofences';
        //return this.networkService.GET(url);
        let url = `company/${_companyID}/geofences`
        return this.networkService.GET(url, callback)
    }
    updateCompanyDetails(data, callback){
        //let url: string = this.globalSettings.baseURL + '/company/' + this.globalSettings.companyID + '/';
        //return this.networkService.PATCH(url,data);
        return this.networkService.PATCH(`company/${_companyID}`,data, callback)
    }
    updateCompanySettings(data, callback){
        //let url: string = this.globalSettings.baseURL + '/companysettings/' + this.globalSettings.companyID + '/';
        //return this.networkService.PATCH(url,data);
        return this.networkService.PATCH(`companysettings/${_companyID}`,data,callback)
    }
    addGeofenceLocation(data, callback){
        //let url:string = this.globalSettings.baseURL + '/geofences/';
        //return this.networkService.POST(url,data);
        return this.networkService.POST("geofences", data, callback)
    }
    deleteGeofence(id, callback){
        //let url: string = this.globalSettings.baseURL + '/geofences/' + id + '/';
        //return this.networkService.DELETE(url)
        return this.networkService.DELETE(`geofences/${id}`, callback)
    }
    updateGeofence(data, callback){
        //let url: string = this.globalSettings.baseURL + '/geofences/' + data.geofence_id + '/';
        //return this.networkService.PATCH(url,data);
        return this.networkService.PATCH(`geofences/${data.geofence_id}`, data, callback)
    }
}