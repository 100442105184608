import React,{Component} from 'react';
import {Modal, ModalBody,ModalHeader} from 'reactstrap';
import Form from '../generalForm';
import PropTypes from 'prop-types';
import UserClass from '../../models/user.model';

export default class SendEmployeeNotification extends Component {
    constructor(props){
        super(props)
        this.state = {
            user: props.user,
            message: ""
        }
        this.onChange = this.onChange.bind(this);
    }
    componentWillReceiveProps(nextProps){
        if(JSON.stringify(nextProps.user) !== JSON.stringify(this.props.user))
            this.setState({user: new UserClass(nextProps.user).model})
    }
    onChange(evt){  
        this.setState({message: evt.target.value})
    }
    render() {
        const {displayModal, user} = this.props
        var formItems=[{label: "Message", data: "", id: "notification", type: "textarea"}]
        return (
            <div>
            <Modal isOpen={displayModal} toggle={this.props.onClick}>
                <ModalHeader toggle={this.props.onClick}>{`Send ${user.first_name} a notification`}</ModalHeader>
                <ModalBody>
                    <Form submissionButtonTitle="Send" formItems={formItems} onSubmit={()=>this.props.onClick(this.state.user, this.state.message)} onChange={this.onChange}/>
                </ModalBody>
            </Modal>
            </div>
        );
    }
}
SendEmployeeNotification.propTypes = {
    onClick: PropTypes.func,
    user: PropTypes.object,
    displayModal: PropTypes.bool
}