import PropTypes from 'prop-types';

export default class EmployeeModel {
    static propTypes = {
        my_type: PropTypes.string,
        empId: PropTypes.number,
        comp_fk: PropTypes.number,
        inLot: PropTypes.boolean,
        onDrive: PropTypes.boolean,
        onBreak: PropTypes.boolean,
        clockedIn: PropTypes.boolean,
        store_number: PropTypes.number,
        logged_in: PropTypes.boolean,
        latitude: PropTypes.number,
        longitude: PropTypes.number,
        admin_level: PropTypes.number,
        user_id: PropTypes.number,
        steps: PropTypes.number
    }
    model = {
        my_type: "employeeModel",
        empId: null,
        comp_fk: 1,
        inLot: null,
        onDrive: null,
        onBreak: null,
        clockedIn: null,
        store_number: null,
        logged_in: false,
        latitude: null,
        longitude: null,
        admin_level: 1,
        user_id: null,
        steps: null
    }
    constructor(props){
        if(props)
            this.model = props
    }
    createEmployee(user){
        this.model.empId = user.user_id
        this.model.user_id = user.user_id
    }
    getImage(){
        return './assets/images/person.svg'
    }

    getStatus(){
        let msg = 'Not clocked in'
        if (this.model.clockedIn)
            msg = 'clocked in'
        return msg
    }
}