import React,{Component} from 'react';
import { Container, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';

export default class Grid extends Component{
    static defaultProps = {
        fluid: true
    }
    constructor(props){
        super(props)
        this.getCols = this.getCols.bind(this);
    }
    getCols(components,rowIndex){
        const {colSize} = this.props
        return components.map((component,index)=><Col md={colSize && (Array.isArray(colSize) ? colSize[index] : colSize)} key={`row-${rowIndex}-Col-${index}-`}>{component}</Col>)
    }
    getGrid(){
        const {row_count, col_count, components, gutters} = this.props
        var components_to_render = []//, max=row_count*col_count;

        for(var i=0;i<row_count;i++){
            let startIndex = i*col_count,
                endIndex = startIndex+col_count
            components_to_render.push(<p key={`emptyTag-${i}`}/>, <Row noGutters={gutters} key={`row-${i}`}>{this.getCols(components.slice(startIndex,endIndex),i)}</Row>)
        }
        return components_to_render
    }
    render(){
        return(
            <Container fluid={this.props.fluid}>
                {this.getGrid()}
            </Container>
        )
    }
}
Grid.propTypes = {
    col_count: PropTypes.number,
    row_count: PropTypes.number,
    components: PropTypes.array,
    gutters: PropTypes.bool,
    colSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
    fluid: PropTypes.bool
  };