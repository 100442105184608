import React,{Component} from 'react';
import { DropdownList } from 'react-widgets';
import PropTypes from 'prop-types';

export default class Search extends Component {
  
  constructor(props){
    super(props)
    this.state = {
      selectedOption: null,
    }
  }
  onChange = (selectedOption)=>{
    this.props.onChange(selectedOption)
    this.setState({selectedOption})
  }
  render() {
    const { selectedOption } = this.state;
    const {data, valueField, textField, className, placeholder, messages} = this.props;
    return (
      <DropdownList filter className={className} messages={messages}
        data={data} value={selectedOption} placeholder={placeholder ? placeholder : "Search..."} selectIcon={null}
        allowCreate="onFilter" onChange={selectedOption => this.onChange(selectedOption)}
        itemComponent={this.props.format} valueField={valueField} textField={textField}/>
    );
  }
}
Search.propTypes = {
  textField: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  valueField: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
  format: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  messages: PropTypes.object
}
