import React,{Component} from 'react';
import CollapsibleMenu from '../components/collapsibleMenu';
import Grid from '../components/grid';
import Navbar from '../components/navbar';
import {Alert} from 'reactstrap';
import GeneralDataForm from '../components/generalDataForm';
import CompanyService from '../services/company.service';
import CompanyModel from '../models/company.model';
import CompanySettingsModel from '../models/companySettings.model';
import GeofenceSearchList from '../components/settingsPage/geofenceSearchPanel';
import EditBasicCompanyInfo from '../components/settingsPage/editBasicCompanyInfo';
import EditCompanySettings from '../components/settingsPage/editCompanySettings';
import AddCompanyGeofence from '../components/settingsPage/addGeofenceForm';
import GoogleGeocodeService from '../services/googleGeocode.service';
import EditCompanyGeofenceModal from '../components/settingsPage/editGeofenceModal';

const menuItems = [
    {label:"Manage my account", submenuItems:["View basic information", "Edit basic information"]},
    {label:"Manage location services", submenuItems:["Registered locations", /*"View inactive locations",*/ "Add new locations"]},
    {label:"Manage notifications", submenuItems:["View grace periods", "Edit grace periods"]}
]

export default class Employee extends Component{
    companyService = new CompanyService();
    
    constructor(props){
        super(props)
        this.state = {
            //activeCollapsibleItem: 'View basic information',
            activeCollapsibleItem: 'Registered locations',
            companyDetails: [],
            displayAlert: false,
            alertMsg: "",
            alertColor: "",
            companySettings: [],
            companyGeofences: [],
            geofenceToEdit: null,
            displayModal: false
        }
        this.onCollapsibleMenuItemClicked=this.onCollapsibleMenuItemClicked.bind(this);
        this.renderContent = this.renderContent.bind(this);
        this.getCompanySettings = this.getCompanySettings.bind(this);
        this.getCompanyDetails = this.getCompanyDetails.bind(this);
        this.getCompanyGeofences = this.getCompanyGeofences.bind(this);
        this.updateCompanyInfo = this.updateCompanyInfo.bind(this);
        this.editBasicInfo = this.editBasicInfo.bind(this);
        this.setCompanyGeofence = this.setCompanyGeofence.bind(this);
        this.setGracePeriods = this.setGracePeriods.bind(this);
        this.getCompanyGeofenceToEdit = this.getCompanyGeofenceToEdit.bind(this);
        this.updateGeofence = this.updateGeofence.bind(this);
    }
    componentWillMount(){
        this.getCompanyDetails();
        this.getCompanySettings();
        this.getCompanyGeofences();
    }
    onCollapsibleMenuItemClicked(evt,clickedItem){
        this.setState({activeCollapsibleItem: clickedItem})
    }
    getCompanyDetails(){
        this.companyService.getCompanyDetails(res=>{
            if(res.success){
                var company = new CompanyModel(res.success)
                this.setState({company: company.model,companyDetails: company.getCompanyData()})
            }
            else
                this.setState({color: "danger", alertMsg: "Unable to obtain company details", displayAlert:true})
        })
    }
    getCompanySettings(){
        this.companyService.getCompanySettings(res=>{
            if(res.success){
                var company = new CompanySettingsModel(res.success)
                this.setState({settings: company.model ,companySettings: company.getCompanySettings()})
            }
            else
                this.setState({color: "danger", alertMsg: "Unable to obtain company settings", displayAlert:true})
        })
    }
    getCompanyGeofences(){
        this.companyService.getCompanyGeofences(res=>{
            const companyGeofences = res.success
            if(companyGeofences){
                this.setState({companyGeofences})
            }
            else
                this.setState({color: "danger", alertMsg: "Unable to obtain company geofences", displayAlert:true})
        })
    }
    updateCompanyInfo(company){
        this.companyService.updateCompanyDetails(company, response =>{
                if(response.success){
                    var updatedCompany = new CompanyModel(company)
                    this.setState({company: updatedCompany.model, companyDetails: updatedCompany.getCompanyData(), color: "success", alertMsg: "Succesfully updated company details", displayAlert:true})
                }else
                    this.setState({color: "danger", alertMsg: "Unable to update company details", displayAlert:true})
            } 
        )
    }
    setCompanyGeofence(geofence, address){
        var googleGeocodeService = new GoogleGeocodeService()
        googleGeocodeService.geocodeAddress(address, (response, error)=>{
            if(error)
                this.setState({alertColor: "danger", alertMsg: error, displayAlert: true})
            else{
                geofence.latitude = response.lat.toPrecision(7)
                geofence.longitude = response.lng.toPrecision(7)
                geofence.address = response.formattedAddress
                this.companyService.addGeofenceLocation(geofence, response =>{
                    if(response.success){
                        this.getCompanyGeofences()
                        this.setState({color: "success", alertMsg: "Successfully added new geofence", displayAlert:true})
                    }else
                        this.setState({color: "danger", alertMsg: "Unable to add geofence", displayAlert:true})
                })
            }
        })
    }
    editBasicInfo(editedCompany, wasAddressEdited){
        var googleGeocodeService = new GoogleGeocodeService()
        if(wasAddressEdited){
            googleGeocodeService.geocodeAddress(editedCompany.address, (response,error) => {
                if(error)
                    this.setState({alertColor: "danger", alertMsg: error, displayAlert: true})
                else{
                    editedCompany.latitude = response.lat.toPrecision(7)
                    editedCompany.longitude = response.lng.toPrecision(7)
                    this.updateCompanyInfo(editedCompany)
                }
            })
        }
        else
            this.updateCompanyInfo(editedCompany)
    }
    setGracePeriods(settings){
        this.companyService.updateCompanySettings(settings, response =>{
            if(response.success){
                this.getCompanySettings()
                this.setState({alertColor: "success", alertMsg: "Successfully updated grace period(s)", displayAlert: true})
            }else
                this.setState({alertColor: "danger", alertMsg: "Error updating grace period(s)", displayAlert: true})
        })
    }
    getCompanyGeofenceToEdit(action, geofenceToEdit){
        switch(action){
            case "edit":
                this.setState({geofenceToEdit, displayModal: true})
                break;
            default:
                console.log("Nothing set for this action: ", action)
        }
    }
    updateGeofence(geofence){
        if(geofence && geofence.geofence_id){
            this.companyService.updateGeofence(geofence,response=>{
                if(response.success){
                    this.getCompanyGeofences()
                    this.setState({alertColor: "success", alertMsg: "Successfully updated geofence", displayAlert: true, displayModal: false})
                }else
                    this.setState({alertColor: "danger", alertMsg: "There was a problem updating the geofence", displayAlert: true, displayModal: false})
            })
        }else
            this.setState({displayModal: false})
    }
    renderContent(){
        const {activeCollapsibleItem, companyDetails, companySettings, companyGeofences, company, settings} = this.state
            switch(activeCollapsibleItem){
                case "Registered locations":
                    return  <GeofenceSearchList onSearch={(geofence)=>this.getCompanyGeofenceToEdit("edit", geofence)} companyGeofences={companyGeofences} onClick={this.getCompanyGeofenceToEdit}/>
                case "Add new locations":
                    return  <AddCompanyGeofence onClick={this.setCompanyGeofence}/>  
                case "Edit basic information":
                    return <EditBasicCompanyInfo onClick={this.editBasicInfo} company={company} formItems={companyDetails}/>
                case "Edit grace periods":
                    return <EditCompanySettings onClick={this.setGracePeriods} settings={settings} formItems={companySettings}/>  
                case "View basic information":
                    return <GeneralDataForm formItems={companyDetails}/> 
                case "View grace periods":
                    return <GeneralDataForm formItems={companySettings}/>
                case "View inactive locations":
                    return <h1>List of inactive locations</h1>
                default:
                    return null
            }
    }
    render(){
        const {displayAlert, alertColor, alertMsg, geofenceToEdit, displayModal} = this.state
        return(
                <div className="settingsPage">
                    <Navbar navItems={[]}/>
                    {displayModal &&
                        <EditCompanyGeofenceModal onClick={this.updateGeofence} geofence={geofenceToEdit} displayModal={displayModal}/>
                    }
                    <Alert isOpen={displayAlert} toggle={()=>this.setState({displayAlert: false})} color={alertColor}>{alertMsg}</Alert>
                    <Grid row_count={1} col_count={2} noGutters={false} colSize={6} components={[
                        <CollapsibleMenu menuItems={menuItems} onClick={this.onCollapsibleMenuItemClicked} activeItem={this.state.activeCollapsibleItem}/>,
                        this.renderContent()
                    ]} /> 
                    
                </div>
        )
    }
}