import PropTypes from 'prop-types';

export default class CompanyGeofencesModel {
    static propTypes = {
        my_type: PropTypes.string,
        geofence_id: PropTypes.number,
        address: PropTypes.string,
        latitude: PropTypes.number,
        longitude: PropTypes.number,
        radius: PropTypes.number,
        title: PropTypes.string,
        website: PropTypes.string,
        phone_number: PropTypes.string,
        store_number: PropTypes.number,
        company_fk: PropTypes.number,
        is_active: PropTypes.bool
    }
    model = {
        my_type: "Geofences",
        geofence_id: null,
        address: null,
        latitude: null,
        longitude: null,
        radius: null,
        title: null,
        website: null,
        phone_number: null,
        store_number: null,
        company_fk: 1,
        is_active: true
    }
    model_alias = {
        title: "Store name",
        address: "Address",
        radius: "Radius",
        phone_number: "Phone number",
        store_number: "Store number",
        website: "Website",
        is_active: "active"
    }
    formItems_add_geofence=[
        {inline:true, id:"title", label:"Company Name", type: "text"},
        {inline:true, id:"store_number", label:"Store Number", type: "number"},
        {inline:false, id:"website", label:"Website", type: "text"},
        {inline:false, id:"phone_number", label:"Phone Number", type: "text"},
        {inline:false, id:"radius", label:"Radius (meters)", type: "number"},
        {inline:true, id:"street", label:"Street", type: "text"},
        {inline:true, id:"city", label:"City", type: "text"},
        {inline:false, id:"state", label:"State", type: "text"},
        {inline:false, id:"postalCode", label:"Postal code", type: "number"},
    ]
    getModel(){
        return this.model
    }
    get_geofence(){
        var entries = []
        for(var key in this.model_alias){
            if(key === "is_active")
                entries.push(
                    {
                        inline: true,
                        id: key,
                        label: this.model_alias[key],
                        data: this.model[key],
                        placeholder: null,
                        rootLabel: "Status",
                        type: "checkbox"
                    }
                )
            else
                entries.push({
                    inline: true,
                    id: key,
                    label: this.model_alias[key],
                    data: this.model[key],
                    placeholder: this.model[key]
                })
        }    
        return entries
    }
    constructor(props){
        if(props)
            this.model = props
    }
}