import React, { Component } from 'react';
import Moment from 'moment';
import { DatePicker } from 'antd';

const { RangePicker } = DatePicker;

export default class Header extends Component {
    render() {
        const { heading } = this.props
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-4">
                        <h3>{heading}</h3>
                    </div>
                    <div className="col-8">
                        <RangePicker format="MM/DD/YYYY" allowClear={false} onChange={this.props.onDateChange} defaultValue={[Moment().day(0).utc(), Moment().utc()]} />
                    </div>
                </div>
            </div>
        )
    }
}