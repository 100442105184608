import PropTypes from 'prop-types';

export default class PushNotificationModel {
    static defaultProps = {
        my_type: "MessageResponse",
        rec_id: void 0,
        message: void 0,
        username: void 0,
    }
    static propTypes = {
        my_type: PropTypes.string,
        rec_id: PropTypes.number,
        message: PropTypes.string,
        username: PropTypes.string,
    }
    sendNotificationToAll_model = {
        company_id:	1,
        sender_id: 1,
        username: "nick",
        message: ""
    }
    getModel(){
        return this.props
    }
    setNotification_all(message){
        this.sendNotificationToAll_model.message = message
    }
}