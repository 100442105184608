import React,{Component} from 'react';
import Search from './search';
import List from './list';
import PropTypes from 'prop-types';

export default class UserSearchList extends Component{
    static defaultProps = {
        formatSearchItems: ({item})=>{
            //({item})=><span>{`${item.first_name} ${item.last_name}`}</span>
            const {first_name, last_name, display} = item
            if(last_name && first_name)
                return( 
                    <span>
                        {`${item.first_name} ${item.last_name}`}
                    </span>
                ) 
            return(
                <span>{display}</span>
            )
        },
        formatListItems: (item)=>{
            //item=>`${item.first_name} ${item.last_name}`
            const { last_name, first_name, display } = item
            if(last_name && first_name)
                return `${last_name}, ${first_name} `
            else
                return `${display}`
        }
    }
    render(){
        const {users, messages, selectedUser, defaultUser} = this.props
        return (
            <div className="userSearchList">
                <Search messages={messages} data={users} onChange={this.props.onClick} valueField="id" textField={"last_name"} format={this.props.formatSearchItems} defaultValue={{first_name: "", last_name: "", id: null}}/>
                <List messages={messages} defaultUser={defaultUser} onClick={this.props.onClick} items={users} format={this.props.formatListItems} currentSelection={selectedUser}/>
            </div>
        )
    }
}
UserSearchList.propTypes = {
    users: PropTypes.arrayOf(PropTypes.object),
    onClick: PropTypes.func,
    messages: PropTypes.object,
    selectedUser: PropTypes.object,
    defaultUser: PropTypes.object,
    formatSearchItems: PropTypes.func,
    formatListItems: PropTypes.func
}