import React,{Component} from 'react';
import PropTypes from 'prop-types';
import Search from '../search';
import {Form, FormGroup} from 'reactstrap';
import Pagination from '../paginator/pagination';
import EmployeeCard from './employeeCard';

export default class UserSearchPanel extends Component{
    constructor(props){
        super(props)
        var itemsCountPerPage = props.itemsCountPerPage ? props.itemsCountPerPage : 4, usersToDisplay=[], i=0;
        if(props.users.length>0){
            for(i;i<itemsCountPerPage;i++)
                usersToDisplay.push(props.users[i])
        }
        this.state = {
            activePage: 1,
            usersToDisplay: usersToDisplay,
            itemsCountPerPage: itemsCountPerPage
        }
        this.handleChange = this.handleChange.bind(this);
    }
    componentWillReceiveProps(nextProps){
        if(JSON.stringify(nextProps.users) !== this.props.users)
            this.handleChange(this.state.activePage, nextProps.users)
    }
    handleChange(activePage, nextUsers){
        const {itemsCountPerPage} = this.state
        var usersToDisplay=[], users = nextUsers ? nextUsers : this.props.users;

        let tempMax =(activePage*itemsCountPerPage),i=tempMax-itemsCountPerPage, max = tempMax>users.length ? users.length : tempMax
        for(i;i<max;i++)
            usersToDisplay.push(users[i])
        this.setState({activePage, usersToDisplay})
    }
    formatSearch = ({item})=><span>{`${item.first_name} ${item.last_name}`}</span>
    formatTextField(item){
        console.log("formatTextField: ", item)
        if(item && item.last_name && item.first_name)
            return `${item.last_name}, ${item.first_name}`
        return ''
    }
    render(){
        const {activePage, itemsCountPerPage, usersToDisplay} = this.state
        const {users,sendNotifications} = this.props
        return(
            <Form className="employeeDetailForm">
                <FormGroup className="employeeDetailFormGroup">
                    <Search data={users} onChange={this.props.onSearch} valueField="id" textField={this.formatTextField} format={this.formatSearch} defaultValue={{first_name: "", last_name: "", id: null}}/>
                </FormGroup>
                <FormGroup className="employeeDetailFormGroup">
                    <Pagination
                        //pageRangeDisplayed={10}
                        activePage={activePage}
                        itemsCountPerPage={itemsCountPerPage}
                        totalItemsCount={users.length}
                        onChange={this.handleChange}
                    />
                </FormGroup>
                {
                    usersToDisplay.map(employee=>
                        <FormGroup key={`employeeCard-${employee.id}`} className="employeeDetailFormGroup">
                            <EmployeeCard employee={employee} onClick={this.props.onClick} sendNotifications={sendNotifications}/>
                        </FormGroup>
                    )
                }
                
            </Form>
        )
    }
}
UserSearchPanel.propTypes = {
    users: PropTypes.arrayOf(PropTypes.object),
    onSearch: PropTypes.func,
    onClick: PropTypes.func,
    sendNotifications: PropTypes.bool
}