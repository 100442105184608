import React,{Component} from 'react';
import {Modal, ModalBody,ModalHeader, Button} from 'reactstrap';
import PropTypes from 'prop-types';


export default class SelectedSearchModal extends Component {
    render() {
        const {displayModal, user} = this.props
        return (
            <div>
            <Modal isOpen={displayModal} toggle={this.props.onClick}>
                <ModalHeader toggle={this.props.onClick}>Select an option</ModalHeader>
                <ModalBody>
                    <div className="d-flex justify-content-center">
                        <Button color="primary" onClick={this.props.onEdit}>{`Edit ${user.first_name}'s information`}</Button>
                    </div>
                    <div className="d-flex justify-content-center">
                        <Button color="primary" onClick={this.props.onSendNotification}>{`Send ${user.first_name} a notification`}</Button>
                    </div>
                  </ModalBody>
            </Modal>
            </div>
        );
    }
}
SelectedSearchModal.propTypes = {
    onClick: PropTypes.func,
    user: PropTypes.object,
    displayModal: PropTypes.bool
}