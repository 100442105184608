import React,{Component} from 'react';
import {Button,Container, Row,Col} from 'reactstrap';
import PropTypes from 'prop-types';

export default class CompanyCard extends Component{
    render(){
        const {company, sendNotifications} = this.props
        return(
                    <Container className="employeeCard">
                        <Row>
                            <Col sm={4} className="employeeCardLabel">Store name:</Col>
                            <Col sm={8} className="employeeCardLabel">
                                <strong>{company.title}</strong>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4} className="employeeCardLabel">Address:</Col>
                            <Col sm={8} className="employeeCardLabel">
                                <strong>{company.address}</strong>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4} className="employeeCardLabel">Website:</Col>
                            <Col sm={8} className="employeeCardLabel">
                                <strong>{company.website}</strong>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4} className="employeeCardLabel">Radius:</Col>
                            <Col sm={8} className="employeeCardLabel">
                                <strong>{company.radius}</strong>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4} className="employeeCardLabel">Phone number:</Col>
                            <Col sm={8} className="employeeCardLabel">
                                <strong>{company.phone_number}</strong>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4} className="employeeCardLabel">Store number:</Col>
                            <Col sm={8} className="employeeCardLabel">
                                <strong>{company.store_number}</strong>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4} className="employeeCardLabel">Status:</Col>
                            <Col sm={8} className="employeeCardLabel">
                                {/* <strong>{company.is_active ? "Active" : "Inactive"}</strong> */}
                                <strong>Active</strong>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4}>
                                <Button className="clear-fix float-left" size="sm" color="primary" onClick={()=>this.props.onClick("edit", company)}>Edit</Button>
                            </Col>
                            {sendNotifications &&
                                <Col sm={8}>
                                    <Button className="clear-fix float-left" size="sm" color="primary" onClick={()=>this.props.onClick("notification", company)}>Send Notification</Button>
                                </Col>
                            }
                        </Row>
                    </Container>
        )
    }
}
CompanyCard.propTypes={
    company: PropTypes.object,
    onClick: PropTypes.func,
    sendNotifications: PropTypes.bool
}