import UserService from '../services/user.service';
import EmployeeService from '../services/employee.service';
import CompanySerice from '../services/company.service';

export const SET_EMPLOYEES = "SET_EMPLOYEES";
export const SET_USERS = "SET_USERS";
export const SET_ACTIVE_USERS = "SET_ACTIVE_USERS";
export const SET_CURRENT_EMPLOYEES = "SET_CURRENT_EMPLOYEES";
export const SET_ALL_EMPLOYEES = "SET_ALL_EMPLOYEES";
export const SERVICE_ERROR = "SERVICE_ERROR";
export const SET_CLOCKEDIN_EMPLOYEES = "SET_CLOCKEDIN_EMPLOYEES";
export const SET_CLOCKEDIN_USERS = "SET_CLOCKEDIN_USERS";
export const SET_COMPANY_GEOFENCES = "SET_COMPANY_GEOFENCES";

const employeeService = new EmployeeService();
const userService = new UserService();
const companyService = new CompanySerice();

export function set_all_employees(employees){
    return {
        type: SET_ALL_EMPLOYEES,
        employees
    }
}
export function set_current_employees(employees){
    return {
        type: SET_CURRENT_EMPLOYEES,
        employees
    }
}
export function set_all_users(users){
    return {
        type: SET_USERS,
        users
    }
}
export function set_active_users(users){
    return {
        type: SET_ACTIVE_USERS,
        users
    }
}
export function service_error(service){
    return{
        type: SERVICE_ERROR,
        service
    }
}
export function set_clockedin_employees(employees){
    return{
        type: SET_CLOCKEDIN_EMPLOYEES,
        employees
    }
}
export function set_clockedin_users(users){
    return{
        type: SET_CLOCKEDIN_USERS,
        users
    }
}
export function set_company_geofences(geofences){
    return {
        type: SET_COMPANY_GEOFENCES,
        geofences
    }
}
export function get_company_geofences(){
    return function(dispatch){
        companyService.getCompanyGeofences(res =>{
            if(res.success){
                //console.log(res.success)
                dispatch(set_company_geofences(res.success))
            }else
                dispatch(service_error("company geofences"))
        })
    }
}
export function get_users(){
    return function(dispatch){
        userService.getUsers(res =>{
            if(res && res.success){
                let activeUsers = [];
                res.success.map(user => user.is_active && activeUsers.push(user))
                dispatch(get_employees(activeUsers))
                dispatch(set_active_users(activeUsers))
                dispatch(set_all_users(res.success))
            }else
                dispatch(service_error("active users"))
        })
    }
}
export function get_employees(activeUsers){
    return function(dispatch){
        employeeService.getEmployees(res =>{
            if(res.success){
                let current_employees = [], employees=res.success, i=0, clockedIn_employees=[], clockedIn_users=[];
                for(i; i<employees.length; i++){
                    activeUsers.map(user =>{ 
                        user.id === employees[i].user_id && current_employees.push(employees[i])
                        
                        if(employees[i].clocked_in === true && user.id === employees[i].user_id)
                            clockedIn_users.push(user)
                        return 0
                    })
                    employees[i].clocked_in && clockedIn_employees.push(employees[i])
                }
                dispatch(set_clockedin_users(clockedIn_users))
                dispatch(set_clockedin_employees(clockedIn_employees))
                dispatch(set_all_employees(employees))
                dispatch(set_current_employees(current_employees))
            }
            else
                dispatch(service_error("employees"))
        })
    }
}