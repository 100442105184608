
export const _companyID = 1;
export const baseURL = "http://52.10.0.37";
export const _objectTypeKey = "my_type";
export const _localObjectTypeKey = "my_type";
export const _token = 'master_token';
export const _trackingRefreshInterval=3000;
/*export class GlobalSettingsService {
    private _companyID: number = 1;
    get companyID(): number { return this._companyID; }
    set companyID(input: number) { this._companyID = input; }

    private _baseURL: string = "http://52.10.0.37";
    get baseURL(): string { return this._baseURL; }
    set baseURL(input: string) { this._baseURL = input; }

    private _objectTypeKey: string = "my_type";
    get objectTypeKey(): string { return this._objectTypeKey; }

    private _localObjectTypeKey: string = "my_type";
    get localObjectTypeKey(): string { return this._localObjectTypeKey; }

    private _token: string = 'master_token';
    get token(): string { return this._token; }
    set token(input: string) { this._token = input; }

    private _trackingRefreshInterval: number = 3000;
    get trackingRefreshInterval(): number { return this._trackingRefreshInterval}
}*/