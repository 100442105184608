import React,{Component} from 'react';
import UserClass from '../../models/user.model';
import GeneralForm from '../generalForm';
import {Alert} from 'reactstrap';
import EmployeeService from '../../services/employee.service';
import UserService from '../../services/user.service';
import EmployeeClass from "../../models/employee.model";

export default class AddEmployeeForm extends Component{
    userService = new UserService()
    employeeService = new EmployeeService()
    constructor(props){
        super(props)
        this.state = {
            newUser: new UserClass().model,
            displayAlert: false,
            alertMsg: "",
            alertColor: "success"
        }
        this.onChange = this.onChange.bind(this);
        this.validateAddedUser = this.validateAddedUser.bind(this);
        this.addEmployee = this.addEmployee.bind(this);
    }
    onChange(evt,item){
        var newUser = JSON.stringify(this.state.newUser)
        newUser = JSON.parse(newUser)
        newUser[item] = evt.target.value   
        this.setState({newUser})
    }
    validateAddedUser(){
        const {newUser} = this.state
        if(newUser.last_name && newUser.first_name && newUser.username && newUser.email && newUser.password){
            this.addEmployee(newUser)
        }else
            this.setState({alertMsg: "Please fill out all fields in the form to create a new employee", alertColor:"danger", displayAlert:true})
    }
    addEmployee(user){
        this.userService.signUpNewUser(user, res=>{
            if(res.success){
                var employee = new EmployeeClass()
                employee.createEmployee(res.success)
                this.employeeService.createEmployee(employee.model,res=>{
                    if(res.success){
                        this.props.refresh()
                        this.setState({alertMsg: "Successfully added new employee", alertColor:"success", displayAlert:true,newUser: new UserClass().model})
                    }    
                    else
                        this.setState({alertMsg: "Problem adding employee try again later", alertColor:"danger", displayAlert:true})
                })
            }else
                this.setState({alertMsg: "Problem adding employee try again later", alertColor:"danger", displayAlert:true})
        })
    }
    render(){
        const userClass = new UserClass()
        var formItems = userClass.get_userData_addEmployee()
        const {displayAlert, alertColor, alertMsg} = this.state
        return (
            <div>
                <Alert isOpen={displayAlert} color={alertColor} toggle={()=>this.setState({displayAlert:false})}>
                    {alertMsg}
                </Alert>
                <GeneralForm onChange={this.onChange} formItems={formItems} onSubmit={this.validateAddedUser}/> 
            </div>
        )
    }
}