//import fetch from 'isomorphic-fetch';
import {GOOGLE_API_KEY} from '../keys/googleApiKey';
import google from '@google/maps';

export default class GeocodeService{
    googleMapsClient;
    constructor(props){
        this.googleMapsClient = google.createClient({
            key: GOOGLE_API_KEY
          });
    }
    geocodeAddress(location, callback){
        this.googleMapsClient.geocode({
            address: location
            }, 
            function(error, response) {
                if (!error) {
                    const {json} = response
                    //console.log(response)
                    var succesfulResponse = {
                        lat: json.results[0].geometry.location.lat,
                        lng: json.results[0].geometry.location.lng,
                        formattedAddress: json.results[0].formatted_address
                    }
                    callback(succesfulResponse,null)
                }
                else
                    callback(null, "Error retreiving address")
            }
        );
    }
    /*reverseGeocodeAddress(lat, lng){
      this.googleMapsClient.reverseGeocode({

      })
      return this.waitForMapsToLoad().pipe(
          switchMap(()=>{
              return new Observable(observer =>{
                  var latlng = {lat: parseFloat(lat), lng: parseFloat(lng)};
                  let request = {latLng: latlng};  
                  this.geocoder.geocode(request, (results, status) => {
                      if (status == google.maps.GeocoderStatus.OK) {
                        //console.log('Geocoding complete!');
                        //console.log("reverseGeocodeAddress: ", results)
                        observer.next({
                          address: results[0].formatted_address
                        });
                      } else {
                          console.log('Error - ', results, ' & Status - ', status);
                          observer.next({});
                      }
                      observer.complete();
                    });
              })
          })
      )
    }*/
  /*reverseGeocodeAddress(lat, lng){
    return this.waitForMapsToLoad().pipe(
        switchMap(()=>{
            return new Observable(observer =>{
                var latlng = {lat: parseFloat(lat), lng: parseFloat(lng)};
                let request = {latLng: latlng};  
                this.geocoder.geocode(request, (results, status) => {
                    if (status == google.maps.GeocoderStatus.OK) {
                      //console.log('Geocoding complete!');
                      //console.log("reverseGeocodeAddress: ", results)
                      observer.next({
                        address: results[0].formatted_address
                      });
                    } else {
                        console.log('Error - ', results, ' & Status - ', status);
                        observer.next({});
                    }
                    observer.complete();
                  });
            })
        })
    )
  }*/
}