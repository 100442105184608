import PropTypes from 'prop-types';

export default class UserModel {
    static propTypes = {
        my_type: PropTypes.string,
        empId: PropTypes.number,
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        username: PropTypes.string,
        password: PropTypes.string,
        email: PropTypes.string,
        last_login: PropTypes.number,
        is_superuser: PropTypes.bool,
        is_staff: PropTypes.bool,
        is_active: PropTypes.bool,
        date_joined: PropTypes.number
    }
    model = {
        my_type: "User",
        empId: null,
        first_name: null,
        last_name: null,
        username: null,
        password: null,
        email: null,
        last_login: null,
        is_superuser: null,
        is_staff: null,
        is_active: true,
        date_joined: null
    }
    model_alias = {
        first_name: "First name",
        last_name: "Last name",
        username: "Username",
        email: "Email",
        is_active: "active"
    }
    model_alias_addEmployee = {
        first_name: "First name",
        last_name: "Last name",
        username: "Username",
        email: "Email",
        password: "Password"
    }
    get_fullName(){
        return this.props.last_name + ', ' + this.props.first_name;
    }
    get_image(){
        return './assets/images/person.svg';
    }
    get_userData(){
        var entries = []
        for(var key in this.model_alias){
            if(key === "is_active")
                entries.push(
                    {
                        inline: true,
                        id: key,
                        label: this.model_alias[key],
                        data: this.model[key],
                        placeholder: null,
                        rootLabel: "Status",
                        type: "checkbox"
                    }
                )
            else
                entries.push(
                    {
                        inline: true,
                        id: key,
                        label: this.model_alias[key],
                        data: this.model[key],
                        placeholder: this.model[key]
                    }
                )
        }    
        return entries
    }
    get_userData_addEmployee(){
        var entries = []
        for(var key in this.model_alias_addEmployee){
            entries.push({
                inline: true,
                id: key,
                label: this.model_alias_addEmployee[key],
                data: this.model_alias_addEmployee[key],
                placeholder: this.model_alias_addEmployee[key]
            })
        }    
        return entries
    }
    constructor(props){
        if(props)
            this.model = props
    }
}