import React,{Component} from 'react';
import {Button,Container, Row,Col} from 'reactstrap';
import PropTypes from 'prop-types';

export default class EmployeeCard extends Component{
    render(){
        const {employee, sendNotifications} = this.props
        return(
                    <Container className="employeeCard">
                        <Row>
                            <Col sm={4} className="employeeCardLabel">First Name:</Col>
                            <Col sm={8} className="employeeCardLabel">
                                <strong>{employee.first_name}</strong>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4} className="employeeCardLabel">Last Name:</Col>
                            <Col sm={8} className="employeeCardLabel">
                                <strong>{employee.last_name}</strong>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4} className="employeeCardLabel">Employee ID:</Col>
                            <Col sm={8} className="employeeCardLabel">
                                <strong>{employee.id}</strong>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4} className="employeeCardLabel">Username:</Col>
                            <Col sm={8} className="employeeCardLabel">
                                <strong>{employee.username}</strong>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4} className="employeeCardLabel">Email:</Col>
                            <Col sm={8} className="employeeCardLabel">
                                <strong>{employee.email}</strong>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4} className="employeeCardLabel">Status:</Col>
                            <Col sm={8} className="employeeCardLabel">
                                <strong>{employee.is_active ? "Active" : "Inactive"}</strong>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4}>
                                <Button className="clear-fix float-left" size="sm" color="primary" onClick={()=>this.props.onClick("edit", employee)}>Edit</Button>
                            </Col>
                            {sendNotifications &&
                                <Col sm={8}>
                                    <Button className="clear-fix float-left" size="sm" color="primary" onClick={()=>this.props.onClick("notification", employee)}>Send Notification</Button>
                                </Col>
                            }
                        </Row>
                    </Container>
        )
    }
}
EmployeeCard.propTypes={
    employee: PropTypes.object,
    onClick: PropTypes.func,
    sendNotifications: PropTypes.bool
}