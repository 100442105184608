import {SET_COMPANY_GEOFENCES} from '../actions/actionCreators';

const initial_state = {
    companyGeofences: []
}

export default function(state=initial_state, action){
    switch(action.type){
        case SET_COMPANY_GEOFENCES:
            return{
                ...state,
                companyGeofences: action.geofences
            }
        default:
            return{
                ...state
            }
    }
}