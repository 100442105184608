import PropTypes from 'prop-types';

export default class CompanyModel {
    static propTypes = {
        my_type: PropTypes.string,
        packageFk: PropTypes.number,
        companyId: PropTypes.number,
        name: PropTypes.string,
        phone_number: PropTypes.string,
        email: PropTypes.string,
        website: PropTypes.string,
        hours: PropTypes.string,
        address: PropTypes.string,
        latitude: PropTypes.string,
        longitude: PropTypes.string
    }
    model = {
        my_type: "companyModel",
        packageFk: null,
        companyId: null,
        name: null,
        phone_number: null,
        email: null,
        website: null,
        address: null,
        hours: null,
        latitude: null,
        longitude: null
    }
    model_alias_companyModel = {
        name: "Company name",
        phone_number: "Phone number",
        email: "Email",
        website: "Website",
        address: "Address",
        hours: "Hours",
    }
    constructor(props){
        if(props)
            this.model = props
    }
    getCompanyData(){
        var entries = []
        for(var key in this.model_alias_companyModel){
            entries.push({
                inline: true,
                id: key,
                label: this.model_alias_companyModel[key],
                data: this.model[key],
                placeholder: this.model[key]
            })
        }    
        return entries
    }
    getModel(){
        return this.props
    }
}