import React,{Component} from 'react';
import {Form, FormGroup} from 'reactstrap';
import CompanyCard from './companyCard';
import Search from '../search';
import Pagination from '../paginator/pagination';
import PropTypes from 'prop-types';

export default class GeofenceSearchPanel extends Component{
    constructor(props){
        super(props)
        var itemsCountPerPage = props.itemsCountPerPage ? props.itemsCountPerPage : 4, geofencesToDisplay=[], i=0;
        if(props.companyGeofences.length>0){
            for(i;i<itemsCountPerPage;i++)
            geofencesToDisplay.push(props.companyGeofences[i])
        }
        this.state = {
            activePage: 1,
            geofencesToDisplay: geofencesToDisplay,
            itemsCountPerPage: itemsCountPerPage
        }
        this.handleChange=this.handleChange.bind(this);
    }
    componentWillReceiveProps(nextProps){
        if(JSON.stringify(nextProps.companyGeofences) !== this.props.companyGeofences)
            this.handleChange(this.state.activePage, nextProps.companyGeofences)
    }
    handleChange(activePage, nextGeofences){
        const {itemsCountPerPage} = this.state
        var geofencesToDisplay=[], companyGeofences = nextGeofences ? nextGeofences : this.props.companyGeofences;

        let tempMax =(activePage*itemsCountPerPage),i=tempMax-itemsCountPerPage, max = tempMax>companyGeofences.length ? companyGeofences.length : tempMax
        for(i;i<max;i++)
            geofencesToDisplay.push(companyGeofences[i])
        this.setState({activePage, geofencesToDisplay})
    }
    onCompanyClicked(action,employee){
        console.log("employee clicked: ", action, employee)
    }
    formatSearch = ({item})=><span>{`${item.title}`}</span>
    formatTextField = (item)=>{
        //(item)=>`${item.title}`
        if(item && item.title)
            return item.title
        return ''
    }
    render(){
        const {activePage, itemsCountPerPage, geofencesToDisplay} = this.state
        const {companyGeofences} = this.props
        return (
            <Form className="employeeDetailForm">
                <FormGroup className="employeeDetailFormGroup">
                    <Search data={companyGeofences} onChange={this.props.onSearch} valueField="geofence_id" textField={this.formatTextField} format={this.formatSearch} defaultValue={{title: "", geofence_id: null}}/>
                </FormGroup>
                <FormGroup className="employeeDetailFormGroup">
                    <Pagination
                        //pageRangeDisplayed={10}
                        activePage={activePage}
                        itemsCountPerPage={itemsCountPerPage}
                        totalItemsCount={companyGeofences.length}
                        onChange={this.handleChange}
                    />
                </FormGroup>
                {geofencesToDisplay.map((company,index)=>
                    <FormGroup key={`companyCard-${index}`} className="employeeDetailFormGroup">
                        <CompanyCard company={company} onClick={this.props.onClick}/>
                    </FormGroup>
                )}    
            </Form>
        )
    }
}
GeofenceSearchPanel.propTypes = {
    companyGeofences: PropTypes.arrayOf(PropTypes.object),
    onClick: PropTypes.func,
    onSearch: PropTypes.func,
}