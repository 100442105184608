import React,{Component} from 'react';
import tiles from '../json/tile.json';
import Grid from '../components/grid';
import Tile from '../components/tile';
import {Link} from 'react-router-dom';
import Navbar from '../components/navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class Home extends Component{
    onLogout(){
        console.log("log user out")
    }
    render(){
        var components = tiles.tiles.map((tile,index)=>
            <Tile hasCardImg key={`tile-${index}`} title={<Link className="text-muted" to={tile.link}>{tile.title}</Link>} src={tile.src}/>
        )
        var navItem = {
            type: "btn",
            name: "logout",
            label: <FontAwesomeIcon size="2x" color="white" icon="sign-out-alt" />
        }
        return( 
            <div className="homePage">
                <Navbar navItems={[navItem]} onClick={this.onLogout}/>
                <Grid fluid={false} row_count={2} col_count={3} components={components} gutters={false}/>
            </div>
        )
    }
}