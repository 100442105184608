import React,{Component} from 'react';
import ScheduleSetter from '../components/schedulePage/scheduleSetter';
import Navbar from '../components/navbar';
import Grid from '../components/grid';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as actions from '../actions/actionCreators';
import SearchList from '../components/userSearchList';
import ScheduledDays from '../components/schedulePage/scheduledDays';
import ScheduleService from '../services/schedule.service';
import ScheduleModel from '../models/schedule/schedule.day.model';
import EmployeeService from '../services/employee.service';
import {Alert} from 'reactstrap';

class Schedule extends Component{
    scheduleService = new ScheduleService();
    employeeService = new EmployeeService();

    constructor(props){
        super(props)
        this.state = {
            selectedUser: {},
            scheduledDays: [],
            selectedEmployee: {},
            alertMsg: "",
            alertColor: "",
            alertIsOpen: false,
        }
        this.props.get_users();
        this.getEmployeeSchedule=this.getEmployeeSchedule.bind(this);
        this.setEmployeeSchedule=this.setEmployeeSchedule.bind(this);
    }
    getEmployeeSchedule(selectedUser){
        let selectedEmployee = null
        this.props.currentEmployees.map(employee => {
            if(employee.user_id === selectedUser.id) 
                selectedEmployee=employee
            return 0
        })
        this.scheduleService.getScheduleForEmployee(selectedEmployee.emp_id, (res, error) =>{
            if(res.success){
                let scheduledDays = res.success.map(day => new ScheduleModel(day))
                this.setState({scheduledDays, selectedUser})
            }else{
                console.log(error)
                this.setState({selectedUser, alertColor:"danger", alertMsg:`There was an issue retreiving ${selectedUser.first_name}'s schedule`, alertIsOpen:true})
            }
        })
        this.setState({selectedEmployee})
    }
    setEmployeeSchedule(scheduledTimes,message){
        scheduledTimes.map(day =>{
            let scheduleModel = new ScheduleModel()
            scheduleModel.model.start_time = day.start
            scheduleModel.model.end_time = day.end
            scheduleModel.model.message = message
            scheduleModel.model.emp_fk = this.state.selectedEmployee.emp_id
            
            this.scheduleService.addWorkdayToEmployeeSchedule(scheduleModel.model, (res, error)=>{
                if(error)
                    this.setState({alertColor: "danger", alertIsOpen:true, alertMsg: "Problem adding schedule"})
                else{
                    this.getEmployeeSchedule(this.state.selectedUser)
                    this.setState({alertColor: "success", alertIsOpen:true, alertMsg: "Successfully added schedule"})
                } 
            })
            return 0
        })
    }
    render(){
        const {activeUsers} = this.props
        const {selectedUser, scheduledDays, alertColor, alertMsg, alertIsOpen} = this.state
        return(
            <div className="schedulePage">
                <Navbar navItems={[]}/>
                <Alert isOpen={alertIsOpen} color={alertColor} toggle={()=>this.setState({alertIsOpen: false})}>{alertMsg}</Alert>
                {JSON.stringify(this.state.selectedUser) !== JSON.stringify({}) ? 
                    <Grid row_count={1} col_count={3} colSize={[3,4,5]} gutters={false} components={[
                        <SearchList users={activeUsers} onClick={this.getEmployeeSchedule} selectedUser={selectedUser}/>,
                        <ScheduleSetter employee={selectedUser} onClick={this.setEmployeeSchedule}/>,
                        scheduledDays.length === 0 ?
                            <p>{`There are no scheduled days for ${selectedUser.first_name}`}</p>
                            :
                            <ScheduledDays employee={selectedUser} scheduledDays={scheduledDays} update={this.getEmployeeSchedule}/>
                        
                        ]}
                    />
                    :
                    <Grid row_count={1} col_count={2} colSize={[3,9]} gutters={true} components={[
                        <SearchList users={activeUsers} onClick={this.getEmployeeSchedule} selectedUser={selectedUser}/>,
                        <p>Select an employee to set a schedule for</p>
                        ]}
                    />
                }
            </div>
        )
    }
}
function mapStateToProps(state){
    return{
        activeUsers: state.user.activeUsers,
        currentEmployees: state.employee.currentEmployees,
    }
}
function mapDispatchToProps(dispatch){
    return bindActionCreators(actions,dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(Schedule);