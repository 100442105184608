import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../actions/actionCreators';
import SearchList from '../components/userSearchList';
import Grid from '../components/grid';
import EmployeeService from '../services/employee.service';
import Navbar from '../components/navbar';
import { DatePicker, Table } from 'antd';
import moment from 'moment';
import { CSVLink } from "react-csv";

const { RangePicker } = DatePicker;
const FILTER = {
    all: "all",
    single: "single"
}
class HoursWorked extends Component {
    employeeService = new EmployeeService();
    constructor(props) {
        super(props)

        this.onClick = this.onClick.bind(this);
        this.getUserById = this.getUserById.bind(this);
        this.getEmployeeById = this.getEmployeeById.bind(this);
        this.getHoursForDate = this.getHoursForDate.bind(this);
        this.state = {
            selectedUser: null,
            alpha: moment().day(0).utc().format("X"),
            beta: moment().utc().format("X")
        }

        this.props.get_users()
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.currentEmployees.length !== this.props.currentEmployees.length)
            this.getAllEmpHrs(nextProps.currentEmployees, this.state.alpha, this.state.beta)
    }
    getAllEmpHrs(currentEmployees, alpha, beta) {
        var selectedUser = [];
        currentEmployees.map((employee, index) =>{
            this.employeeService.getEmployeeCumulativeHours({ pk: employee.emp_id, alpha, beta }, (result, error) => {
                if(error){
                    console.log("error fetching results")
                }else if(result && typeof result.success === "number"){
                    let user = this.getUserById(employee.user_id)
                    user && selectedUser.push({...user, hoursworked: result.success, fullname: `${user.last_name}, ${user.first_name}`})
                    this.setState({selectedUser, filter: FILTER.all})
                }
            })
        })
    }
    getUserById(id){
        const {activeUsers} = this.props
        for(var i in activeUsers)
            if(id === activeUsers[i].id)
                return activeUsers[i]
        return null
    }
    getEmployeeById(id){
        const {currentEmployees} = this.props
        for(var i in currentEmployees)
            if(id === currentEmployees[i].user_id)
                return currentEmployees[i]
        return null
    }
    onClick(user) {
        if(user.id === "all_users")
            this.getAllEmpHrs(this.props.currentEmployees, this.state.alpha, this.state.beta)
        else{
            let employee = this.getEmployeeById(user.id)
            if (employee)
                this.employeeService.getEmployeeHoursByDay({ pk: employee.emp_id, alpha: this.state.alpha, beta: this.state.beta }, (result, error) => {
                    if(error){
                        console.log("error fetching hours for individual user")
                        this.setState({filter: FILTER.single, selectedUser:{...user, hoursworked: {}, fullname: `${user.last_name}, ${user.first_name}`}})
                    }else if(result && result.success)
                        this.setState({filter: FILTER.single, selectedUser:{...user, hoursworked: result.success, fullname: `${user.last_name}, ${user.first_name}`}})
                })
        }
    }
    getHoursForDate(alpha, beta) {
        const {filter, selectedUser} = this.state
        const {currentEmployees} = this.props
        if(filter === FILTER.all)
            this.getAllEmpHrs(currentEmployees, alpha, beta)
        else
            console.log(alpha, beta)
        this.setState({alpha, beta})
    }
    render() {
        const { activeUsers, selectedUser } = this.props;
        
        const {filter} = this.state
        return (
            <div className="hours-worked-page">
                <Navbar navItems={[]} />
                <Grid row_count={1} col_count={2} colSize={[3, 7]} gutters={false} components={[
                    <SearchList users={[{id:"all_users", display: "Display All Users"}].concat(activeUsers)} onClick={this.onClick} />,
                    <EmployeeHours filter={filter} {...this.state} getHoursForDate={this.getHoursForDate} />
                ]}
                />
            </div>

        )
    }
}
class EmployeeHours extends Component {
    constructor(props){
        super(props)
        this.allEmployee_columns = [
            {
              title: 'Employee',
              label: 'Name',
              dataIndex: 'fullname',
              key: 'fullname'
            },
            {
              title: 'Hours worked',
              label: 'Hours',
              dataIndex: 'hoursworked',
              key: 'hoursworked',
            }
        ];
        this.singleEmployee_columns = [
            {
              title: 'Date',
              label: 'Date',
              key: 'date',
              dataIndex: 'date',
            },
            {
              title: 'Hours worked',
              label: 'Hours',
              key: 'hoursworked',
              dataIndex: 'hoursworked',
            }
        ];
        this.state = {
            currDate: [moment().day(0).utc().format("MM/DD/YYYY"),moment().utc().format("MM/DD/YYYY")]
        }
    }
    onChange = (date, currDate) => {
        //console.log("in onChange in hours page: ", date, currDate)
        if(date.length > 0 ){
            const alpha = date[0].format("X"),
                beta = date[1].format("X");
            this.props.getHoursForDate(alpha, beta)
            this.setState({currDate})
        }
    }
    header = (title, csvLinkProps) => {
        return (
            <div className="clearfix">
                <div className="float-left">{title}</div>
                <div className="float-right row">
                        <strong className="col-2">Filter:&nbsp;</strong>
                        <RangePicker className="col-9" format="MM/DD/YYYY" allowClear={false} onChange={this.onChange} defaultValue={[moment().day(0).utc(), moment().utc()]} />
                        <CSVLink className="col-1" {...csvLinkProps} ><img src="/images/download.png" alt="Export"/></CSVLink>
                </div>
            </div>
        )
    }
    render() {
        const {selectedUser, filter} = this.props
        
        if ( filter === FILTER.all && Array.isArray(selectedUser) ) {
            var allUserDataSet_wStartEndDate = []
            for(var i=0; i<selectedUser.length; i++){
                var obj = selectedUser[i]
                allUserDataSet_wStartEndDate.push(
                    {

                        'datestart': this.state.currDate[0],
                        'dateend': this.state.currDate[1],
                        ...obj
                    }
                )
            }
            return (
                <Table
                    columns={this.allEmployee_columns}
                    dataSource={selectedUser}
                    bordered
                    title={() => this.header('Hours for all employees', 
                    {
                            filename: "allEmployeeHours.csv", 
                            data: allUserDataSet_wStartEndDate, 
                            headers: this.allEmployee_columns.concat([
                                {
                                    label: 'Date Start',
                                    key: 'datestart',
                                },
                                {
                                    label: 'Date End',
                                    key: 'dateend',
                                }
                            ])
                        }
                    )}
                    rowKey={(payload, index) => payload.id}
                />
            )
        }else if (filter === FILTER.single && selectedUser.hoursworked) {
            const { hoursworked, fullname, first_name, last_name } = selectedUser
            const filename = `hoursWorkedWeekOf_${this.state.currDate[0]}_${first_name}${last_name}.csv`
            var dataSet = []
            for (var key in hoursworked) {
                dataSet.push({
                    date: key,
                    hoursworked: hoursworked[key],
                    id: selectedUser.id,
                    fullname: fullname
                })
            }
            
            return (
                <Table
                    columns={this.singleEmployee_columns}
                    dataSource={dataSet}
                    bordered
                    title={() => this.header(`Hours for ${fullname}`, 
                        {
                            filename, 
                            data: dataSet, 
                            headers: [{
                                label: 'Name',
                                key: 'fullname'
                            }].concat(this.singleEmployee_columns)
                        }
                    )}
                    rowKey={(payload, index) => `${payload.id}-${index}`}
                />
            )
        }
        else
            return <div className="loading-container"><div className="loading-employee-hrs"></div></div>
    }
}
function mapStateToProps(state) {
    return {
        activeUsers: state.user.activeUsers,
        currentEmployees: state.employee.currentEmployees,
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(actions, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(HoursWorked);