import React,{Component} from 'react';
import GeneralForm from '../generalForm';
import {Alert} from 'reactstrap';
import CompanyService from '../../services/company.service';
import CompanyGeofenceClass from "../../models/companyGeofences.model";

export default class AddGeofenceForm extends Component{
    companyService = new CompanyService()
    constructor(props){
        super(props)
        this.state = {
            newGeofence: new CompanyGeofenceClass().model,
            displayAlert: false,
            alertMsg: "",
            alertColor: "success",
            state: null,
            zip: null,
            street: null,
            city: null

        }
        this.onChange = this.onChange.bind(this);
        this.validateAddedGeofence = this.validateAddedGeofence.bind(this);
        this.addGeofence = this.addGeofence.bind(this);
    }
    onChange(evt,item){
        var newGeofence = JSON.stringify(this.state.newGeofence)
        newGeofence = JSON.parse(newGeofence)
        newGeofence[item] = evt.target.value   
        this.setState({newGeofence})
    }
    validateAddedGeofence(){
        const {newGeofence} = this.state
        if(newGeofence.state && newGeofence.city && newGeofence.street && newGeofence.postalCode && newGeofence.website && newGeofence.radius && newGeofence.title && newGeofence.store_number && newGeofence.phone_number){
            this.addGeofence(newGeofence)
        }else
            this.setState({alertMsg: "Please fill out all fields in the form to add a new geofence", alertColor:"danger", displayAlert:true})
    }
    addGeofence(geofence){
        var newGeofence = new CompanyGeofenceClass();
        const address = `${geofence.street} ${geofence.city}, ${geofence.state} ${geofence.postalCode}`
        for(var key in newGeofence.model_alias)
            newGeofence.model[key] = geofence[key]
        this.props.onClick(newGeofence.model, address)
    }
    render(){
        const companyGeofenceClass = new CompanyGeofenceClass()
        var formItems = companyGeofenceClass.formItems_add_geofence

        const {displayAlert, alertColor, alertMsg} = this.state
        return (
            <div>
                <Alert isOpen={displayAlert} color={alertColor} toggle={()=>this.setState({displayAlert:false})}>
                    {alertMsg}
                </Alert>
                <GeneralForm onChange={this.onChange} formItems={formItems} onSubmit={this.validateAddedGeofence}/> 
            </div>
        )
    }
}