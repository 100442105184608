
import NetworkService from './network.service';

export default class UserService{
    networkService = new NetworkService()

    getUsers(callback, query){
        if(query)
            return this.networkService.QUERY("user",query,callback)
        else
            return this.networkService.GET("user", callback);
    }
    
    getUser(id, callback, query){
        if(query)
            return this.networkService.QUERY(`user/${id}`, query, callback)
        else
            return this.networkService.GET(`user/${id}`, callback);
    }

    createUser(proposedEmployee, callback){
        //let url: string = this.globalSettings.baseURL + '/user/';
        return this.networkService.POST(`user/${proposedEmployee}`, callback);
    }
    
    editUser(user,callback){
        //let url: string = this.globalSettings.baseURL + '/user/' + user.empId + "/"
        return this.networkService.PATCH(`user/${user.id}`, user, callback)
    }
    signUpNewUser(user, callback){
        //signup/username/(?P<username>.+)/password/(?P<password>.+)/first_name/(?P<first_name>.+)/last_name/(?P<last_name>.+)/$ [name='signup']
        //let url: string = this.globalSettings.baseURL + '/signup/username'+user.username+'/password/'+user.password+'/firs_name/'+user.first
        //let url: string = `${this.globalSettings.baseURL}/signup/username/${user.username}/password/${user.password}/first_name/${user.first_name}/last_name/${user.last_name}/` 
        var url = `signup/username/${user.username}/password/${user.password}/first_name/${user.first_name}/last_name/${user.last_name}`
        return this.networkService.GET(url, callback)
    } 
    sendPushNotification(notification, callback){
        //console.log("notification: ", notification)
        //let url: string = `${this.globalSettings.baseURL}/message/`;
        return this.networkService.POST("message",notification, callback)
    }
    sendPushNotificationToAll(notification, callback){
        //console.log("notification: ", notification)
        //let url: string = `${this.globalSettings.baseURL}/message/`;
        return this.networkService.POST(`message/company/${notification.company_id}`,notification, callback)
    }
}