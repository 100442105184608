import React,{Component} from 'react';
import CollapsibleMenu from '../components/collapsibleMenu';
import Grid from '../components/grid';
import Navbar from '../components/navbar';
import {menuItems} from '../tests/constants';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as actions from '../actions/actionCreators';
import UserSearchPanel from '../components/employeePage/userSearchPanel';
import EditEmployeeModal from '../components/employeePage/editEmployeeModal';
import UserService from '../services/user.service';
import UserClass from '../models/user.model';
import AddEmployeeForm from '../components/employeePage/addEmployeeForm';
import SendEmployeeNotification from '../components/employeePage/sendEmployeeNotification';
import {Alert} from 'reactstrap';
import Form from '../components/generalForm';
import NotificationModel from '../models/pushNotification.model';
import SelectedSearchModal from '../components/employeePage/onSelectedModal';

class Employee extends Component{
    userService = new UserService()
    constructor(props){
        super(props)
        this.props.get_users()
        this.state = {
            activeCollapsibleItem: 'View/Edit employees',
            displayModal: false,
            modalType: '',
            selectedEmployee: null,
            newUser: new UserClass().model,
            displayAlert: false,
            alertMsg: "",
            alertColor: "success",
            notification: ""
        }
        this.onCollapsibleMenuItemClicked=this.onCollapsibleMenuItemClicked.bind(this);
        this.renderContent = this.renderContent.bind(this);
        this.onEmployeeClicked = this.onEmployeeClicked.bind(this);
        this.onEmployeeModalClick = this.onEmployeeModalClick.bind(this);
        this.sendNotification = this.sendNotification.bind(this);
        this.sendNotificationToAll = this.sendNotificationToAll.bind(this);
        this.onSearch = this.onSearch.bind(this);
    }
    onCollapsibleMenuItemClicked(evt,clickedItem){
        this.setState({activeCollapsibleItem: clickedItem})
    }
    onEmployeeClicked(modalType,selectedEmployee){
        this.setState({selectedEmployee, modalType, displayModal:true})
    }
    onSearch(selectedEmployee){
        const {activeCollapsibleItem} = this.state
        if(activeCollapsibleItem==="View/Edit employees")
            this.setState({selectedEmployee, modalType: "searched", displayModal:true})
        else if(activeCollapsibleItem==="View/Edit inactive employees")
            this.setState({selectedEmployee, modalType: "edit", displayModal:true})
    }
    onEmployeeModalClick(data){
        if(data && data.id)
            this.userService.editUser(data,res=>{
                res.success && this.props.get_users()
                this.setState({displayModal: false, modalType:""})
            })
        else
            this.setState({displayModal: false, modalType:""})
    }
    sendNotificationToAll(){
        const {notification} = this.state
        var notificationAll = new NotificationModel()
        notificationAll.setNotification_all(notification)
        this.userService.sendPushNotificationToAll(notificationAll.sendNotificationToAll_model,res=>
            console.log(res)
        )
    }
    renderContent(activeCollapsibleItem){
        const {activeUsers, users} = this.props
            switch(activeCollapsibleItem){
                case "View/Edit inactive employees":
                    var usersToDisplay=[];
                    users.map(user => !user.is_active && usersToDisplay.push(user))
                    return <UserSearchPanel users={usersToDisplay} onSearch={this.onSearch} onClick={this.onEmployeeClicked}/>
                case "View/Edit employees": 
                    return <UserSearchPanel sendNotifications users={activeUsers} onSearch={this.onSearch} onClick={this.onEmployeeClicked}/>
                case "Add employee":
                    return <AddEmployeeForm refresh={this.props.get_users}/> 
                case "Send notification": 
                    var formItems=[{label: "Message", data: "", id: "notification", type: "textarea"}]
                    return <Form formItems={formItems} onSubmit={this.sendNotificationToAll} onChange={(evt)=>this.setState({notification: evt.target.value})}/>
                default:
                    return null
            }
    }
    sendNotification(user,msg){
        if(user.id && user.username && msg){
            let notification = {message: msg, rec_id: user.id, username: user.username}
            this.userService.sendPushNotification(notification,res =>{
                if(res.success)
                    this.setState({displayModal: false, modalType:"", displayAlert: true, alertColor:"success", alertMsg:"Successfully sent employee notification"})
                else
                    this.setState({displayModal: false, modalType:"", displayAlert: true, alertColor:"danger", alertMsg:"Problem sending employee notification"})
            })
        }else
            this.setState({displayModal: false, modalType:""})
    }
    render(){
        const {activeCollapsibleItem, modalType, displayModal, selectedEmployee, displayAlert, alertColor, alertMsg} = this.state
        
        return(
                <div className="employeePage">
                    <Navbar navItems={[]}/>
                    <Alert isOpen={displayAlert} color={alertColor} toggle={()=>this.setState({displayAlert: false})}>{alertMsg}</Alert>
                    <Grid row_count={1} col_count={2} noGutters={false} colSize={6}
                        components={[
                            <CollapsibleMenu menuItems={menuItems} onClick={this.onCollapsibleMenuItemClicked} activeItem={this.state.activeCollapsibleItem}/>,
                            this.renderContent(activeCollapsibleItem)
                        ]} 
                    />
                    {modalType === "edit" &&
                        <EditEmployeeModal onClick={this.onEmployeeModalClick} user={selectedEmployee} displayModal={displayModal}/>
                    }
                    {modalType === "notification" &&
                        <SendEmployeeNotification onClick={this.sendNotification} user={selectedEmployee} displayModal={displayModal}/>
                    }
                    {modalType === "searched" &&
                        <SelectedSearchModal activeCollapsibleItem={activeCollapsibleItem} onSendNotification={()=>this.setState({modalType: "notification"})} onEdit={()=>this.setState({modalType: "edit"})} onClick={()=>this.setState({displayModal: false, modalType:""})} user={selectedEmployee} displayModal={displayModal}/>
                    }
                </div>
        )
    }
}
function mapStateToProps(state){
    return{
        activeUsers: state.user.activeUsers,
        users: state.user.users,
    }
}
function mapDispatchToProps(dispatch){
    return bindActionCreators(actions,dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(Employee);