import React,{Component} from 'react';
import ScheduleForm from './scheduleForm';
import Grid from '../grid';
import PropTypes from 'prop-types';
import ScheduleService from '../../services/schedule.service';
import ScheduleModel from '../../models/schedule/schedule.day.model';
import Moment from 'moment';
import {Alert} from 'reactstrap';

export default class ScheduledDays extends Component{
    scheduleService = new ScheduleService()
    constructor(props){
        super(props)
        var scheduledDays = this.getScheduledDays(props.scheduledDays,props.employee)
        this.state={
            scheduledDays: scheduledDays,
            employee: props.employee,
            displayAlert: false,
            alertColor: "info",
            alertMsg: ""
        }
    }
    componentWillReceiveProps(nextProps){
        if(JSON.stringify(nextProps) !== JSON.stringify(this.props)){
            var scheduledDays = this.getScheduledDays(nextProps.scheduledDays,nextProps.employee)
            this.setState({scheduledDays})
        }
    }
    editScheduledDays = (day,state,type) => {
        switch(type){
            case "edit":
                let schedule = new ScheduleModel(day), end,start;
                if(state.endTime){
                    end = Moment(schedule.model.end_time).set(
                        {
                            hour: Moment(state.endTime).hour(),
                            minute: Moment(state.endTime).minute()
                        }
                    ).utc().format()
                    schedule.model.end_time = end
                }
                if(state.startTime){
                    start = Moment(schedule.model.start_time).set(
                        {
                            hour: Moment(state.startTime).hour(),
                            minute: Moment(state.startTime).minute()
                        }
                    ).utc().format()
                    schedule.model.start_time = start
                }
                this.scheduleService.updateScheduleWorkday(schedule.model, res =>{
                    if(res.success){
                        this.props.update(this.state.employee)
                        this.setState({displayAlert: true, alertColor: "success", alertMsg: "Successfully updated schedule"})
                    }
                    else
                        this.setState({displayAlert: true, alertColor: "danger", alertMsg: "Issue updating schedule"})    
                })
                break;
            case "remove":
                this.scheduleService.removeScheduleWorkday(day,res=>{
                    if(res.success){
                        this.props.update(this.state.employee)
                        this.setState({displayAlert: true, alertColor: "success", alertMsg: "Successfully removed day from schedule"})
                    }else
                        this.setState({displayAlert: true, alertColor: "danger", alertMsg: "Issue removing day from schedule"})
                })
                break;
            default:
                console.log("action not set for this case: ", type)
        }
    }
    getScheduledDays(scheduledDays, employee){
        var forms = scheduledDays.map((day,i) => <ScheduleForm key={`scheduleFormComponent-${i}-000${employee.id}`} day={day.model} onEditSchedule={this.editScheduledDays}/>)
        return forms
    }
    render(){
        const {scheduledDays, alertMsg, alertColor, displayAlert} = this.state
        return (
            <div>
                <Alert color={alertColor} isOpen={displayAlert} toggle={()=>this.setState({displayAlert: false})}>{alertMsg}</Alert>
                <Grid row_count={scheduledDays.length} col_count={1} components={scheduledDays} gutters/>
            </div>
        )
    }
}
ScheduledDays.propTypes = {
    employee: PropTypes.object,
    scheduledDays: PropTypes.arrayOf(PropTypes.object),
    update: PropTypes.func
}