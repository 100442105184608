import React from 'react';
import ReactDOM from 'react-dom';
import './css/index.css';
import App from './App';
import {Provider} from 'react-redux';
import 'babel-polyfill';
import configureStore from './store/store';

//import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css'
import "font-awesome/css/font-awesome.min.css";
import 'rc-time-picker/assets/index.css';
import 'react-calendar/dist/entry.nostyle';
import 'react-widgets/dist/css/react-widgets.css';
import 'antd/dist/antd.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import {faSignOutAlt, faSearch, faPencilAlt, faEdit} from '@fortawesome/free-solid-svg-icons'

const store = configureStore();

library.add(faSignOutAlt, faSearch, faPencilAlt, faEdit)
ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
