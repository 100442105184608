import PropTypes from 'prop-types';

export default class CompanySettingsModel {
    static propTypes = {
        my_type: PropTypes.string,
        clockin_grace_period: PropTypes.number,
        break_grace_period: PropTypes.number,
        break_postpone_limit: PropTypes.number,
        break_postpone_time_limit: PropTypes.number,
    }
    model = {
        my_type: "CompanySettings",
        clockin_grace_period: null,
        break_grace_period: null,
        break_postpone_limit: null,
        break_postpone_time_limit: null,
    }
    model_alias = {
        clockin_grace_period: "Clock-in grace period",
        break_grace_period: "Break grace period",
        break_postpone_limit: "Break postpone limit",
        break_postpone_time_limit: "Break postpone time limit",
    }
    getModel(){
        return this.model
    }
    getCompanySettings(){
        var entries = []
        for(var key in this.model_alias){
            entries.push({
                inline: true,
                id: key,
                label: this.model_alias[key],
                data: this.model[key],
                placeholder: this.model[key],
                type: "number"
            })
        }    
        return entries
    }
    constructor(props){
        if(props)
            this.model = props
    }
}