import {SET_ALL_EMPLOYEES, SET_CURRENT_EMPLOYEES, SET_CLOCKEDIN_EMPLOYEES} from '../actions/actionCreators';

const initialState = {
    employees: [],
    currentEmployees: [],
    clockedInEmployees:[{first_name: "Employees", last_name: "All", id: "all"}]
}
export default function(state=initialState, action){
    switch(action.type){
        case SET_ALL_EMPLOYEES:
            return{
                ...state,
                employees: action.employees
            }
        case SET_CURRENT_EMPLOYEES:
            return{
                ...state,
                currentEmployees: action.employees
            }
        case SET_CLOCKEDIN_EMPLOYEES:
            return{
                ...state,
                clockedInEmployees: action.employees
            }
        default: 
            return state
    }
}