import NetworkService  from './network.service';
import {_token} from './global.settings.service';
//import moment from 'moment';

export default class EmployeeService{
    networkService = new NetworkService()

    getEmployees(callback, data){
        return this.networkService.GET("employee", callback);
    }

    // Endpoint: http://timetrack.com/company/1/employees/1
    getEmployee(id,callback, data){
        //let url = this.globalSettings.baseURL + '/employee/' + id + '/token/' + this.globalSettings.token;
        return this.networkService.GET(`employee/${id}/token/${_token}`, callback);
    }

    // Endpoint: http://timetrack.com/employee/
    createEmployee(proposedEmployee,callback){
        return this.networkService.POST("employee", proposedEmployee,callback);
    }

    getEmployeeCumulativeHours({pk, alpha, beta}, callback){
        //console.log("alpha: ", moment.unix(alpha).utc().format("MM/DD/YYYY"));
        //console.log("beta: ", moment.unix(beta).utc().format("MM/DD/YYYY"));

        return this.networkService.GET(`employee/${pk}/hours/between/${alpha}/${beta}/option/3/breakdown/cumulative`, callback);
    }
    getEmployeeHoursByDay({pk, alpha, beta}, callback){
        //console.log("alpha: ", moment.unix(alpha).utc().format("MM/DD/YYYY"));
        //console.log("beta: ", moment.unix(beta).utc().format("MM/DD/YYYY"));
        //employee/<pk>/hours/between/<alpha>/<beta>/option/<option>/breakdown/<breakdown>
        return this.networkService.GET(`employee/${pk}/hours/between/${alpha}/${beta}/option/3/breakdown/days`, callback);
    }
}
