import React,{Component} from 'react';
import { Form, FormGroup, Label } from 'reactstrap';
import PropTypes from 'prop-types';

export default class GeneralDataForm extends Component{
  render() {
    return (
      <Form className="generalForm">
        {
            this.props.formItems.map((formItem,index)=>
                <FormGroup key={`generalDataForm-formGroup-${index}`} inline={formItem.inline}>
                    <Label for={formItem.id}>{formItem.label}</Label>
                    <strong><p id={formItem.id}>{formItem.data}</p></strong>
                </FormGroup>
            )
        }
      </Form>
    );
  }
}
GeneralDataForm.propTypes = {
    formItems: PropTypes.arrayOf(PropTypes.object)
}