import React,{Component} from 'react';
import { Button, Form, FormGroup, Label, Input, Col } from 'reactstrap';
import Calendar from 'react-calendar';
import { DateTimePicker } from "react-widgets";
import Moment from 'moment'
import momentLocalizer from 'react-widgets-moment';
import PropTypes from 'prop-types';

Moment.locale('en')
momentLocalizer()

export default class ScheduleSetter extends Component {
    constructor(props){
        super(props)
        this.state = {
            date: new Date(),
            startTime: null,
            endTime: null,
            message: "",
        }
        this.validateAddedSchedule = this.validateAddedSchedule.bind(this)
    }
    onCalendarChange = date => this.setState({ date })
    onTimePickerChange_start = startTime => this.setState({startTime, endTime: null})
    onTimePickerChange_end = endTime => this.setState({endTime})
    setMessage = evt => this.setState({message: evt.target.value})

    validateAddedSchedule(){
        const {date,startTime,endTime, message} = this.state
        var startDate = Moment(date[0]), 
            endDate = Moment(date[1]), 
            numDaysToScheduleFor = endDate.diff(startDate,"days"),
            scheduledDays = [],
            i=0

        for(i; i<(numDaysToScheduleFor+1); i++){
            scheduledDays.push(
                {
                    start: Moment(date[0]).add(i,'days').set(
                        {
                            hour: Moment(startTime).hour(),
                            minute: Moment(startTime).minute()
                        }
                    ).utc().format(), 
                    end: Moment(date[0]).add(i,'days').set(
                        {
                            hour: Moment(endTime).hour(),
                            minute: Moment(endTime).minute()
                        }
                    ).utc().format()
                }
            )
        }
        this.props.onClick(scheduledDays, message)
    }
    render() {
        const {employee} = this.props
        const {date,startTime,endTime} = this.state
        let isActive= (date[0] && date[1]&&startTime&&endTime) ? true : false,
            minDate = new Date();
        return (
        <Form>
            <Label className="scheduleSetterLabel"for="userName">{`${employee.first_name} ${employee.last_name}'s Schedule`}</Label>
            <FormGroup>
                <Calendar className="customCalendar" minDate={minDate} returnValue="range"
                    onChange={this.onCalendarChange} value={this.state.date} selectRange
                />
            </FormGroup>
            <FormGroup>
                <Label className="scheduleSetterLabel"for="setTimeSchedule">Set Time For All Scheduled Days</Label>
            </FormGroup>
            <FormGroup row>
                <Col md={6}>
                    <Label className="scheduleSetterLabel"for="startTime">Start Time</Label>
                    <DateTimePicker
                        timeFormat={"hh:mm a"} 
                        date={false}
                        onChange={this.onTimePickerChange_start}
                    />
                </Col>
                <Col md={6}>
                    <Label className="scheduleSetterLabel"for="endTime">End Time</Label>
                    <DateTimePicker min={startTime}
                        timeFormat={"hh:mm a"} 
                        date={false}
                        onChange={this.onTimePickerChange_end}
                    />
                </Col>
            </FormGroup>
            <FormGroup>
            <Label className="scheduleSetterLabel"for="exampleSelectMulti">Set Message For All Schedule Days </Label>
            <Input type="textarea" name="text" id="exampleText" onChange={this.setMessage}/>
            </FormGroup>
            <FormGroup>
                <Button disabled={!isActive} size="md" className="clearfix float-right" color="primary" onClick={this.validateAddedSchedule}>Add To Schedule</Button>
            </FormGroup>
        </Form>
        );
    }
}
ScheduleSetter.propTypes = {
    employee: PropTypes.object,
    onClick: PropTypes.func
}