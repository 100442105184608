import React, { Component } from 'react';
import Navbar from '../components/navbar';
import Grid from '../components/grid';
import List from '../components/list';
import SearchList from '../components/userSearchList';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../actions/actionCreators';
import ActionService from '../services/action.service';
import { ActionModel, ActionModel_All } from '../models/actions/action.model';
import Moment from 'moment';
import { Skeleton } from 'antd';
import Header from '../components/activityPage/header';
import '../css/userSearchList.css';

Moment.locale('en')

const MODE = {
    single: "single",
    all: "all"
}

class Activity extends Component {
    actionService = new ActionService();
    allActions = Array(new ActionModel());

    constructor(props) {
        super(props);
        this.state = {
            mode: MODE.all,
            singleUserData: {},
            allUsersData: [], 
            alpha: Moment().day(0).utc().format(),
            beta: Moment().utc().format()
        }
        this.getEmployeeActions = this.getEmployeeActions.bind(this);
        this.renderPage = this.renderPage.bind(this);
        this.formatAllEmployeeActivity = this.formatAllEmployeeActivity.bind(this);
        this.fetchAllUserActions = this.fetchAllUserActions.bind(this);
        this.formatListItems = this.formatListItems.bind(this);
        this.fetchSingleUserActions = this.fetchSingleUserActions.bind(this);
    }
    componentDidMount() {
        const { alpha, beta } = this.state
        this.fetchAllUserActions(alpha, beta)
    }
    fetchAllUserActions(alpha, beta) {
        this.actionService.getAllEmployeeActions(alpha, beta, (result, error) => {
            var allUsersData = [], mode = MODE.all
            if (result && Array.isArray(result.success)) {
                allUsersData = result.success.map(obj => JSON.parse(obj))
                this.setState({ allUsersData, mode, alpha, beta })
            }else   
                this.setState({alpha, beta})
        })
    }
    fetchSingleUserActions(emp_id, alpha, beta){
        this.actionService.getEmployeeActions(emp_id, alpha, beta, (result, error)=>{
            var singleUserData = {}, mode = MODE.single
            if(result && result.success){
                singleUserData = JSON.parse(result.success)
                this.setState({singleUserData, mode, alpha, beta})
            }else   
                this.setState({alpha, beta})
        })
    }
    getEmployeeActions(selectedUser) {
        const {alpha, beta} = this.state
        if (selectedUser.id === "all_users") {
            this.fetchAllUserActions(alpha, beta)
            return
        }
        this.fetchSingleUserActions(selectedUser.emp_id, alpha, beta)
    }
    formatActivity(action) {
        let actionModel = new ActionModel(action),
            image = actionModel.get_icon(),
            heading = actionModel.get_title()
        return <div className="media">
            <img className="align-self-start mr-3" src={image} alt="action" height={64} width={64}></img>
            <div className="media-body">
                <h5 className="mt-0">{heading}</h5>
                {`Start: ${Moment(action.start_date).format("M/DD/YY")}, ${Moment(action.start_date).format("h:mm a")}`}<br />
                {`End: ${Moment(action.end_date).format("M/DD/YY")}, ${Moment(action.end_date).format("h:mm a")}`}
            </div>
        </div>
    }
    formatAllEmployeeActivity(employee) {
        const actionModel = new ActionModel_All(employee)

        const hasAvailableAction = actionModel.hasAvailableAction(),
              heading = actionModel.get_title()
        
        if(hasAvailableAction){
            const image = actionModel.get_icon(),
               start_time = actionModel.get_startTime(),
               end_time = actionModel.get_endTime()
            return( 
                <div className="media">
                    <img className="align-self-start mr-3" src={image} alt="action" height={64} width={64}></img>
                    <div className="media-body">
                        <h5 className="mt-0">{heading}</h5>
                        {start_time}<br />{end_time}
                    </div>
                </div>
            )
        }
        if(heading)
            return( 
                <div className="media">
                    <img className="align-self-start mr-3" src={"images/actions/clock.svg"} alt="action" height={64} width={64}></img>
                    <div className="media-body">
                        <h5 className="mt-0">{heading}</h5>
                    </div>
                </div>
            )
        return <Skeleton />
    }
    formatListItems(item) {
        const { last_name, first_name, display, clocked_in, on_break, on_drive, in_lot } = item
        if (last_name && first_name){
            if(on_drive)
                return <span className="ondrive-colored-bullet"><span style={{color:'black'}}>{`${last_name}, ${first_name}`}</span></span>
            else if(on_break)
                return <span className="onbreak-colored-bullet"><span style={{color:'black'}}>{`${last_name}, ${first_name}`}</span></span>
            else if(in_lot) //on lot refers to lunch
                return <span className="inlot-colored-bullet"><span style={{color:'black'}}>{`${last_name}, ${first_name}`}</span></span>
            else if(clocked_in)
                return <span className="clockedin-colored-bullet"><span style={{color:'black'}}>{`${last_name}, ${first_name}`}</span></span>
            else
                return <span className="clockedout-colored-bullet"><span style={{color:'black'}}>{`${last_name}, ${first_name}`}</span></span>
        }else
            return `${display}`
    }
    onDateChange = (date, currDate) => {
        if (date.length > 0) {
            const { mode, singleUserData } = this.state
            const alpha = date[0].utc().format(), beta = date[1].utc().format();

            if (mode === MODE.all) {
                this.fetchAllUserActions(alpha, beta)
                return
            }
            this.fetchSingleUserActions(singleUserData.emp_id, alpha, beta)
        }
    }
    renderPage() {
        const { mode, allUsersData, singleUserData } = this.state
        const userOptions = [{ id: "all_users", display: "Display All Users" }].concat(allUsersData)
        
        const heading = mode === MODE.single ? `${singleUserData.first_name}'s Summary` : "Action Summary"
        const items = mode === MODE.single ? singleUserData.actions : allUsersData
        const format = mode === MODE.single ? this.formatActivity : this.formatAllEmployeeActivity

        return(
            <Grid col_count={2} row_count={1} colSize={[4,8]} components={[
                <SearchList formatListItems={this.formatListItems} users={userOptions} onClick={this.getEmployeeActions} />,
                <div className="employeeActions">
                    <Header heading={heading} onDateChange={this.onDateChange}/>
                    <div className="customList" style={{ height: `${window.innerHeight - 180}px`, overflow: 'auto' }}>
                        <List flush onClick={()=>{}} items={items} format={format} />
                    </div>
                </div>
            ]}
            />
        )
    }
    render() {
        return (
            <div className="activityPage">
                <Navbar navItems={[]} />
                {this.renderPage()}
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        activeUsers: state.user.activeUsers,
        currentEmployees: state.employee.currentEmployees
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(actions, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(Activity);