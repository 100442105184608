import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Home from './pages/home.page'
import Settings from './pages/setting.page';
import Schedule from './pages/schedule.page';
import Activity from './pages/activity.page';
import Tracking from './pages/tracking.page';
import Employee from './pages/employee.page';
import Help from './pages/help.page';
import LandingPage from './pages/landing.page';
import Hours from './pages/hours.page';
import './css/App.css';

class App extends Component {
  render() {
    return (
        <Router>
          <div>
            <Route exact path="/" component={Home}/>
            <Route path="/settings" component={Settings}/>
            <Route path="/schedule" component={Schedule}/>
            <Route path="/activity" component={Activity}/>
            <Route path="/tracking" component={Tracking}/>
            <Route path="/employee" component={Employee}/>
            <Route path="/help" component={Help}/>
            <Route path="/landing" component={LandingPage}/>
            <Route path="/hours" component={Hours} />
          </div>
        </Router>
    );
  }
}

export default App;
