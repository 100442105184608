//import { ObjectMapperService } from './object.mapper.service';
import fetch from 'isomorphic-fetch';
import {baseURL} from './global.settings.service';
import Cookies from 'js-cookie';

export default class NetworkService {
    URL(host){
        return `${baseURL}/${host}/`
    }
    GET_PARAM_STRING(params){
        return Object.keys(params).map(key=>`${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`,).join('&')
    }
    ENDPOINT(host,query){
        var paramString = this.GET_PARAM_STRING(query)
        return `${host}query?${paramString}`
    }
/*
    async QUERY(host, data, callback){
        const url = this.URL(host)
        const endpoint = this.ENDPOINT(url, data)
        const request = await fetch(endpoint);

        if(request.status===200){
            const json = await request.json();
            return callback({success: json})
        }else
            return callback({error: request})
    }
*/
    async GET(host, callback){
        const url = this.URL(host)
        try{
            const request = await fetch(url).catch(error => callback(null,{error: "error getting request"}) );
            //console.log("get request: ", request)
            if(request.status===200){
                const json = await request.json();
                return callback({success: json})
            }
            return callback(null,{error: "error getting requests"})
        }catch(error){
            console.log("error fetching request: ", error)
            throw callback(null, {error: "error getting requests"})
        } 
    }

    // TODO: need to test the following: POST, PUT
    async POST(host, data, callback){
        const url = this.URL(host);
        const csrftoken = Cookies.get('csrftoken')
        try{
            const request = await fetch(url, {
                method: 'POST',
                body: JSON.stringify(data),
                //credentials: 'include',
                headers: new Headers({
                    'X-CSRFToken': csrftoken,
                    'Content-Type': 'application/json'
                })
            }).catch(err => callback(null,{error: "error getting request"}) )
            if(request.status>=200 && request.status<300){
                return callback({success: "successfully posted request"}, null)
            }
            return callback(null, {error: request})
        }catch(error){
            return callback(null, {error: "error"})
        }
        //.then(response => callback({success: response})).catch(error=>callback({error: error}))
        // let payload= ObjectMapperService.shared.map(data, true);

        // let headers = new HttpHeaders();
        // headers.set('Content-Type', 'application/json');

        // return this.http.post(url, payload, { headers: headers }).map((item) => ObjectMapperService.shared.map(item));
    }
    async DELETE(host, callback){
        const url = this.URL(host);
        const csrftoken = Cookies.get('csrftoken')
        const request = await fetch(url, {
            method: 'DELETE',
            credentials: 'include',
            headers: new Headers({
                'X-CSRFToken': csrftoken,
                'Content-Type': 'application/json'
            })
        }).catch(res => callback({error: res}))
        if(request.status>=200 && request.status<300){
            return callback({success: request})
        }else 
            return callback({error: request})
    //     let headers = new HttpHeaders();
    //     headers.set('Content-Type', 'application/json');

    //     let options = { headers: headers };
        
    //     if(data) {
    //         options['body'] = data
    //     }

    //     return this.http.request('DELETE', url, { body: data, headers: headers });
    }
    async PATCH(host, data, callback){
        const url = this.URL(host);
        const csrftoken = Cookies.get('csrftoken')
        const request = await fetch(url, {
            method: 'PATCH',
            body: JSON.stringify(data),
            credentials: 'include',
            headers: new Headers({
                'X-CSRFToken': csrftoken,
                'Content-Type': 'application/json'
            })
        }).catch(res => callback({error: res}))
        if(request.status===204 || request.status===200){
            return callback({success: request})
        }else 
            return callback({error: request})
        //let payload = ObjectMapperService.shared.map(data, true);
        //return this.http.put(url, payload);
    }
/*
    async PUT(host, data, callback){
        const url = URL(host)
        const request = await fetch(url,{
            method: 'put',
            params: this.GET_PARAM_STRING(data)
        })
        const json = await request.json()
        return callback(json)
        //let payload = ObjectMapperService.shared.map(data, true);
        //return this.http.put(url, payload);
    }

    PATCH(url, data, callback){
        // let headers = new HttpHeaders();
        // headers.set('Content-Type', 'application/json');

        // let payload = ObjectMapperService.shared.map(data, true);
        
        // return this.http.patch(url, payload, { headers: headers });
        const url = this.URL(host);
        const csrftoken = Cookies.get('csrftoken')
        await fetch(url, {
            method: 'PATCH',
            body: JSON.stringify(data),
            credentials: 'include',
            headers: new Headers({
                'X-CSRFToken': csrftoken,
                'Content-Type': 'application/json'
            })
        }).then(response => callback({success: response})).catch(error=>callback({error: error}))
    }
    */
}