import React, { Component } from 'react';
import Collapsible from 'react-collapsible';
import { ListGroup, ListGroupItem } from 'reactstrap';
import "../css/Collapsible/main.scss";
import PropTypes from 'prop-types';

export default class CollapsibleMenu extends Component{
  render(){
    const {menuItems, activeItem} = this.props;
    return(
        <div className="collapsibleMenu">
            {
                menuItems.map( (rootItem,rootIndex) =>
                    <Collapsible trigger={rootItem.label} key={`collapsible-rootItem-${rootIndex}`}>
                        <ListGroup>
                        {
                            rootItem.submenuItems.map( (subItem, subIndex) =>
                                <ListGroupItem key={`collapsible-${rootItem.label}-subitem-${subIndex}`} active={activeItem===subItem} onClick={(evt)=>this.props.onClick(evt,subItem)}>{subItem}</ListGroupItem>
                            )
                        }
                        </ListGroup>
                    </Collapsible>
                    
                )
            }
        </div>

    );
  }

};
CollapsibleMenu.propTypes = {
    menuItems: PropTypes.array,
    onClick: PropTypes.func,
    activeItem: PropTypes.string,
}