import React,{Component} from 'react';
import {Modal, ModalBody,ModalHeader} from 'reactstrap';
import Form from '../generalForm';
import UserClass from '../../models/user.model';
import PropTypes from 'prop-types';


export default class EditUserModal extends Component {
    constructor(props){
        super(props)
        this.state = {
            user: new UserClass(props.user).model,
            originalUser: new UserClass(props.user).model
        }
        this.getUserForm = this.getUserForm.bind(this);
        this.onChange = this.onChange.bind(this);
    }
    componentWillReceiveProps(nextProps){
        if(JSON.stringify(nextProps.user) !== JSON.stringify(this.props.user))
            this.setState({user: new UserClass(nextProps.user).model})
    }
    onChange(evt,item){
        const {originalUser} = this.state
        var user = JSON.stringify(this.state.user)
        user = JSON.parse(user)
        if(item === "is_active")
            user[item] = evt.target.checked
        else
            user[item] = evt.target.value   

        if(user[item].length===0)
            user[item] = originalUser[item]
        this.setState({user})
    }
    getUserForm(user){
        const userClass = new UserClass(user)
        var formItems = userClass.get_userData()
        return <Form submissionButtonTitle="Edit" formItems={formItems} onSubmit={()=>this.props.onClick(this.state.user)} onChange={this.onChange}/>
    }
    render() {
        const {displayModal, user} = this.props
        return (
            <div>
            <Modal isOpen={displayModal} toggle={this.props.onClick}>
                <ModalHeader toggle={()=>this.props.onClick()}>{`Edit ${user.first_name}'s information`}</ModalHeader>
                <ModalBody>
                    {this.getUserForm(user)}
                </ModalBody>
            </Modal>
            </div>
        );
    }
}
EditUserModal.propTypes = {
    onClick: PropTypes.func,
    user: PropTypes.object,
    displayModal: PropTypes.bool
}