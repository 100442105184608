import NetworkService from './network.service';
import {_token} from './global.settings.service';

export default class ScheduleService{
    networkService = new NetworkService()
    getScheduleForEmployee(employee_id, callback) {
        // let url: string = this.globalSettings.baseURL + "/employee/" + employee_id + "/schedule/";
        //let url = "/employee/" + employee_id + "/schedule/token/" + this.globalSettings.token;
        return this.networkService.GET(`employee/${employee_id}/schedule/token/${_token}`, callback);
    }
    getScheduleByUserId(emp_id,callback){
        return this.networkService.GET(`schedule/${emp_id}`, callback)
    }
    // Endpoint: http://52.10.0.37/schedule/
    // addWorkdayToEmployeeSchedule(employee_id: number, workday: ScheduleDayModel): Observable<any> {
    addWorkdayToEmployeeSchedule(workday, callback){
        //let url: string = this.globalSettings.baseURL + "/schedule/";
        return this.networkService.POST(`schedule`, workday, callback);
    }

    // http://host.com/schedule/{schedule_id}/
    updateScheduleWorkday(workday, callback){
        //let url: string = this.globalSettings.baseURL + "/schedule/" + workday.schedule_id + "/";
        return this.networkService.PATCH(`schedule/${workday.schedule_id}`, workday, callback);
    }

    removeScheduleWorkday(workday, callback){
        //console.log(workday)
        //let url: string = this.globalSettings.baseURL + "/schedule/" + workday.schedule_id;
        // return this.networkService.DELETE(url, workday);
        return this.networkService.DELETE(`schedule/${workday.schedule_id}`, callback);
    }

    // http://host.com/schedule/breaks/{schedule_id}/
    getBreaksForWorkday(schedule_id){
        //let url: string = this.globalSettings.baseURL + "/schedule/breaks/" + schedule_id + "/";
        return this.networkService.GET(`schedule/breaks/${schedule_id}`);
    }

    // http://host.com/break/
    addBreakToWorkday(newBreak){
        //let url: string = this.globalSettings.baseURL + "/break/";
        return this.networkService.POST("break", newBreak);
    }

    removeBreak(_break){
        //let url: string = this.globalSettings.baseURL + "/break/" + _break.break_id + "/";
        // return this.networkService.DELETE(url, _break);
        return this.networkService.DELETE(`break/${_break.break_id}`);
    }
}