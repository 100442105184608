import React,{Component} from 'react';
import { Col, Row, Form, Label, Input, Button } from 'reactstrap';
//import BreakList from './breakList';
import { DateTimePicker } from "react-widgets";
import Moment from 'moment'
import momentLocalizer from 'react-widgets-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

Moment.locale('en')
momentLocalizer()

export default class ScheduleForm extends Component {
  constructor(props){
    super(props)
    this.state = {
      editSchedule: false,
      startTime: null,
      endTime: null,
      message: props.day.message
    }
  }
  onChange = date => this.setState({ date })
    
  render() {
    const {day} = this.props
    return (
      <Form className="scheduleForm">
        <Row form>
          <h3>{Moment(day.start_time).format('MMMM DD YYYY')} <span onClick={()=>this.setState({editSchedule: !this.state.editSchedule})}><FontAwesomeIcon size="xs" icon="edit" /></span></h3>
        </Row>
        <Row form>
            <Col md={6}>
              <Label for="exampleEmail" className="scheduleFormLabel">Start Time</Label>
            </Col>
            <Col md={6}>
              <Label for="examplePassword" className="scheduleFormLabel">End Time</Label>
            </Col>
        </Row>
        <Row form>
          <Col md={6}>
            {
              this.state.editSchedule ?
                <DateTimePicker timeFormat={"hh:mm a"} date={false} onChange={startTime => this.setState({ startTime })} placeholder={Moment(day.start_time).format("hh:mm a")}/>
              :
                <strong><p className="scheduleFormData">{Moment(day.start_time).format("hh:mm a")}</p></strong>
            }
          </Col>
          <Col md={6}>
            {this.state.editSchedule ?
              <DateTimePicker timeFormat={"hh:mm a"} date={false} onChange={endTime => this.setState({ endTime })} placeholder={Moment(day.end_time).format("hh:mm a")}/>
              :
              <strong><p className="scheduleFormData">{Moment(day.end_time).format("hh:mm a")}</p></strong>
            }
          </Col>
        </Row>
        <Row form>
          <Col md={6}>
            <Label for="exampleAddress" className="scheduleFormLabel">Message</Label>
          </Col>
          
        </Row>
        <Row form>
          <Col md={12}>
                {this.state.editSchedule ?
                  <Input type="textarea" name="address" id="exampleAddress" onChange={evt => this.setState({message: evt.target.value})} placeholder={day.message}/>
                  :
                  <strong><p className="scheduleFormData">{ day.message }</p></strong>
                }
          </Col>
        </Row>
        {this.state.editSchedule &&
          <Row form>
            <Col md={6}>
              <Button size="sm" outline color="primary" className="clearfix float-left" onClick={()=>this.props.onEditSchedule(this.props.day,this.state,'edit')}>Change data/time</Button>
            </Col>
            <Col md={6}>
              <Button size="sm" outline color="danger" className="clearfix float-right" onClick={()=>this.props.onEditSchedule(this.props.day,this.state,'remove')}>Remove from schedule</Button>
            </Col>
          </Row>
        }
        {/* <div className="breakSchedule">
          <h4>Scheduled breaks</h4>
          <Row form>
            <Col md={6}>
              <Label for="exampleAddress2">Break Time</Label>
              <FormGroup>
                <DateTimePicker timeFormat={"hh:mm a"} date={false} onChange={this.onBreakTimePickerChange}/>
                </FormGroup>
                <Label for="exampleCity">Duration (minutes)</Label>
                <FormGroup>
                    <Input type="number" name="city" id="exampleCity"/>
                </FormGroup>
                <Label for="exampleCity">Break Type</Label>
                <FormGroup>
                  <Input type="select" name="selectMulti" id="exampleSelectMulti" onChange={this.onBreakTypeChange}>
                    <option>Break</option>
                    <option>Lunch</option>
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Button color="primary" block size="sm" >Add</Button>
                </FormGroup>
            </Col>
            <Col md={6}>
              <BreakList />
            </Col>
          </Row>
        </div> */}
      </Form>
    );
  }
}
ScheduleForm.propTypes = {
  onEditSchedule: PropTypes.func,
  day: PropTypes.object
}