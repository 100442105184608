import React,{Component} from 'react';
import Form from '../generalForm';
import CompanyClass from '../../models/company.model';
import PropTypes from 'prop-types';

export default class EditBasicCompanyInfo extends Component {
    constructor(props){
        super(props)
        this.state = {
            editedCompany: new CompanyClass(props.company).model
        }
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }
    componentWillReceiveProps(nextProps){
        if(JSON.stringify(nextProps.company) !== JSON.stringify(this.props.company))
            this.setState({editedCompany: new CompanyClass(nextProps.company).model})
    }
    onChange(evt,item){
        var editedCompany = JSON.stringify(this.state.editedCompany)
        editedCompany = JSON.parse(editedCompany)
        editedCompany[item] = evt.target.value   
        this.setState({editedCompany})
    }
    onSubmit(){
        const {editedCompany} = this.state
        var newCompany = new CompanyClass(this.props.company).model, isAddressEdited = false
        for(var key in editedCompany){
            if(editedCompany[key] !== newCompany[key] && editedCompany[key].length >0){
                if(key === "address")
                    isAddressEdited = true
                newCompany[key] = editedCompany[key]
            }    
        }
        this.props.onClick(newCompany,isAddressEdited)
    }
    render() {
        //const {editedCompany} = this.state
        return <Form formItems={this.props.formItems} onSubmit={this.onSubmit} onChange={this.onChange}/>
    }
}
EditBasicCompanyInfo.propTypes = {
    onClick: PropTypes.func,
    company: PropTypes.object,
    formItems: PropTypes.arrayOf(PropTypes.object)
}