export const menuItems = [
    {label:"Manage Active Employees", submenuItems:["View/Edit employees", "Add employee"]},
    {label:"Manage Inactive Employees", submenuItems:["View/Edit inactive employees"]},
    {label:"Notify All Employees", submenuItems:["Send notification"]}
]
export const employees = [
    {first_name: 'emp1', last_name:"Bunn", id: 1, username: "user1", email: "user1@email.com", is_active:true},
    {first_name: 'emp2', last_name:"Harris", id: 2, username: "user2", email: "user2@email.com", is_active:true},
    {first_name: 'emp3', last_name:"Mondragon", id: 3, username: "user3", email: "user3@email.com", is_active:true},
    {first_name: 'emp4', last_name:"Henning", id: 4, username: "user4", email: "user4@email.com", is_active:true}
]
export const formItems=[
    {inline:true, id:"firstName", label:"First Name", type: "text"},
    {inline:true, id:"lastName", label:"Last Name", type: "text"},
    {inline:false, id:"username", label:"Username", type: "text"},
    {inline:false, id:"email", label:"Email Address", type: "text"},
    {inline:false, id:"password", label:"Password", type: "password"},
    {inline:false, id:"confirmPassword", label:"Confirm Password", type: "password"},
]
export const actions=[
    {action: "Break", start_time: "8/22/18, 8:33 AM", end_time: "8/22/18, 8:46 AM", img: "images/actions/break.svg"},
    {action: "Clocked In", start_time: "8/22/18, 8:33 AM", end_time: "8/22/18, 8:46 AM", img: "images/actions/clock.svg"},
    {action: "Test Drive", start_time: "8/22/18, 8:33 AM", end_time: "8/22/18, 8:46 AM", img: "images/actions/test.drive.svg"},
    {action: "Clocked out", start_time: "8/22/18, 8:33 AM", end_time: "8/22/18, 8:46 AM", img: "images/actions/clock.svg"}
]
export const activity=[
    {inline: false, id: "scheduledHrs", label: "Scheduled Hours", data:"20 hrs" },
    {inline: false, id: "status", label: "Status", data:"Clocked out" },
    {inline: false, id: "distance", label: "Distance", data:"3.0 miles" },
    {inline: false, id: "workHrs", label: "Work Hours", data:"20 hrs" },
    {inline: false, id: "walkingSteps", label: "Walking Steps", data:"3000 steps" }
]