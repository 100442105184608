import React,{Component} from 'react';
import Form from '../generalForm';
import CompanySettingsClass from '../../models/companySettings.model';
import PropTypes from 'prop-types';

export default class editCompanySettings extends Component {
    constructor(props){
        super(props)
        this.state = {
            settings: new CompanySettingsClass(props.settings).model,
            originalSettings: new CompanySettingsClass(props.settings).model
        }
        this.onChange = this.onChange.bind(this);
    }
    componentWillReceiveProps(nextProps){
        if(JSON.stringify(nextProps.settings) !== JSON.stringify(this.props.settings))
            this.setState({settings: new CompanySettingsClass(nextProps.settings).model})
    }
    onChange(evt,item){
        const {originalSettings} = this.state
        var settings = JSON.stringify(this.state.settings)
        settings = JSON.parse(settings)
        settings[item] = parseInt(evt.target.value)
        if(isNaN(settings[item]))
            settings[item] = originalSettings[item]   
        this.setState({settings})
    }
    render() {
        const {settings} = this.state
        return <Form formItems={this.props.formItems} onSubmit={()=>this.props.onClick(settings)} onChange={this.onChange}/>
    }
}
editCompanySettings.propTypes = {
    onClick: PropTypes.func,
    settings: PropTypes.object,
    formItems: PropTypes.arrayOf(PropTypes.object)
}