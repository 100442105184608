import {SET_ACTIVE_USERS, SET_USERS, SET_CLOCKEDIN_USERS} from '../actions/actionCreators';

const initialState = {
    users: [],
    activeUsers: [],
    clockedInUsers: []
}
export default function(state=initialState, action){
    switch(action.type){
        case SET_ACTIVE_USERS:
            return {
                ...state,
                activeUsers: action.users
            }
        case SET_USERS:
            return{
                ...state,
                users: action.users
            }
        case SET_CLOCKEDIN_USERS:
            return{
                ...state,
                clockedInUsers: action.users
            }
        default:
            return state
    }
}