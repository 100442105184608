import React,{Component} from 'react';
import {Modal, ModalBody,ModalHeader} from 'reactstrap';
import Form from '../generalForm';
import GeofenceClass from '../../models/companyGeofences.model';
import PropTypes from 'prop-types';


export default class EditGeofenceModal extends Component {
    constructor(props){
        super(props)
        this.state = {
            geofence: new GeofenceClass(props.geofence).model,
            originalGeofence: new GeofenceClass(props.geofence).model
        }
        this.getGeofenceForm = this.getGeofenceForm.bind(this);
        this.onChange = this.onChange.bind(this);
    }
    componentWillReceiveProps(nextProps){
        if(JSON.stringify(nextProps.geofence) !== JSON.stringify(this.props.geofence))
            this.setState({geofence: new GeofenceClass(nextProps.geofence).model})
    }
    onChange(evt,item){
        const {originalGeofence} = this.state
        var geofence = JSON.stringify(this.state.geofence)
        geofence = JSON.parse(geofence)
        if(item === "is_active")
            geofence[item] = evt.target.checked
        else
            geofence[item] = evt.target.value
            
        if(geofence[item].length===0)
            geofence[item] = originalGeofence[item]
        this.setState({geofence})
    }
    getGeofenceForm(geofence){
        const geofenceClass = new GeofenceClass(geofence)
        var formItems = geofenceClass.get_geofence()
        return <Form formItems={formItems} onSubmit={()=>this.props.onClick(this.state.geofence)} onChange={this.onChange}/>
    }
    render() {
        const {displayModal, geofence} = this.props
        return (
            <div>
            <Modal isOpen={displayModal} toggle={this.props.onClick}>
                <ModalHeader toggle={()=>this.props.onClick()}>{`Edit ${geofence.title}'s information`}</ModalHeader>
                <ModalBody>
                    {this.getGeofenceForm(geofence)}
                </ModalBody>
            </Modal>
            </div>
        );
    }
}
EditGeofenceModal.propTypes = {
    onClick: PropTypes.func,
    geofence: PropTypes.object,
    displayModal: PropTypes.bool
}