import PropTypes from 'prop-types';
import Moment from 'moment';

/* 
    This is separated into two classes
    1. ActionModel is used for a single employee view
    2. ActionModel_All is used for all employee view
*/

export class ActionModel {
    static propTypes = {
        title: PropTypes.string,
        start_time: PropTypes.string,
        end_time: PropTypes.string,
        action_id: PropTypes.number,
        emp_fk: PropTypes.number
    }
    model={
        title: "Action",
        start_time: null,
        end_time: null,
        action_id: null,
        emp_fk: null,
    }
    get_icon(){
       switch(this.model.my_type){
           case "break":
                return "images/actions/break.svg"
            case "lunch":
                return "images/actions/lunch.svg"
            case "clock_out":
            case "clock_in":
                return "images/actions/clock.svg"
            case "drive":
                return "images/actions/test.drive.svg"
            default:
                return "images/actions/clock.svg"
       } 
    }
    get_title(){
        switch(this.model.my_type){
            case "break":
                 return "Break"
             case "lunch":
                 return "Lunch"
             case "clock_in":
                 return "Clock In"
             case "drive":
                 return "Test Drive"
             default:
                 return "Untitled"
        } 
     }
    constructor(props){
        if(props){
            this.model = props
        }    
    }
}

export class ActionModel_All {
    static propTypes = {
        clocked_in: PropTypes.bool,
        on_break: PropTypes.bool,
        on_drive: PropTypes.bool,
        in_lot: PropTypes.bool,
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        actions: PropTypes.array
    }
    get_icon() {
        switch (this.lastestAction.my_type) {
            case "break":
                return "images/actions/break.svg"
            case "lunch":
                return "images/actions/lunch.svg"
            case "clock_out":
            case "clock_in":
                return "images/actions/clock.svg"
            case "drive":
                return "images/actions/test.drive.svg"
            default:
                return "images/actions/clock.svg"
        }
    }
    get_title() {
        if(this.lastestAction)
            switch (this.lastestAction.my_type) {
                case "break":
                    return `${this.employee_name} is on Break`
                case "lunch":
                    return `${this.employee_name} is on Lunch`
                case "drive":
                    return `${this.employee_name} is on a Test Drive`
                case "clock_in":
                    return `${this.employee_name} is on Clocked In`
                default:
                    return `${this.employee_name} is on Clocked Out`
            }
        return `${this.employee_name} has no recent actions`
    }
    get_startTime(){
        if(this.lastestAction.start_date)
            return `Start: ${Moment(this.lastestAction.start_date).format("M/DD/YY")}, ${Moment(this.lastestAction.start_date).format("h:mm a")}`
        
        return "Start: unavailable"
    }
    get_endTime(){
        if(this.lastestAction.end_date)
            return `End: ${Moment(this.lastestAction.end_date).format("M/DD/YY")}, ${Moment(this.lastestAction.end_date).format("h:mm a")}`
        
        return "End: unavailable"
    }
    hasAvailableAction(){
        return this.lastestAction ? true : false
    }
    constructor(props) {
        const { first_name, last_name, on_break, in_lot, on_drive, clocked_in, actions } = props
        this.employee_name = `${last_name}, ${first_name}`
        this.on_break = on_break
        this.in_lot = in_lot
        this.on_drive = on_drive
        this.clocked_in = clocked_in
        this.lastestAction = null
        if(actions && Array.isArray(actions)){
            const index = actions.length-1

            if(index>-1)
                this.lastestAction = actions[index]
        }
    }
}