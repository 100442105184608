import React,{Component} from 'react';
import Navbar from '../components/navbar';
import Grid from '../components/grid';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as actions from '../actions/actionCreators';
import Map from '../components/trackingPage/map';
import TrackList from '../components/trackingPage/trackList';

const allUsersObject = {first_name: "", last_name: "All Employees", clocked_in: true, id:"all", isDefault: true}

class Tracking extends Component{
    constructor(props){
        super(props);
        this.state = {
            selectedEmployee: allUsersObject,
            shouldTrack: false
        }
        this.props.get_users();
        this.props.get_company_geofences();
        this.getEmployeeLocation = this.getEmployeeLocation.bind(this);
        this.disableLiveTracking = this.disableLiveTracking.bind(this);
        this.enableLiveTracking = this.enableLiveTracking.bind(this);
    }
    getEmployeeLocation(selectedEmployee){
        this.setState({selectedEmployee})
    }
    disableLiveTracking(){
        this.setState({shouldTrack: false})
    }
    enableLiveTracking(){
        this.setState({shouldTrack: true})
    }
    render(){
        var all = []
        const {clockedInEmployees, companyGeofences, clockedInUsers} = this.props
        const {selectedEmployee, shouldTrack} = this.state
        if(clockedInUsers.length>0) 
            all = [allUsersObject].concat(clockedInUsers)
        return(
            <div className="trackingPage">
                <Navbar navItems={[]} />
                
                <Grid col_count={2} row_count={1} colSize={[4,8]} components={[
                    <TrackList shouldTrack={shouldTrack} defaultUser={allUsersObject} users={all} getEmployeeLocation={this.getEmployeeLocation}
                        enableLiveTracking={this.enableLiveTracking} disableLiveTracking={this.disableLiveTracking}/>,
                    <Map shouldTrack={shouldTrack} geofences={companyGeofences} availableEmployeesToTrack={clockedInEmployees} 
                        employeeToTrack={selectedEmployee} availableUsersToTrack={clockedInUsers}/>
                    ]}
                />
                
                
                
            </div>
        )
    }
}
function mapStateToProps(state){
    return{
        clockedInUsers: state.user.clockedInUsers,
        clockedInEmployees: state.employee.clockedInEmployees,
        companyGeofences: state.company.companyGeofences
    }
}
function mapDispatchToProps(dispatch){
    return bindActionCreators(actions,dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(Tracking);